import { useDialogContext } from '@podcastsoundboard/ps-lib/context/DialogContext'
import { useGlobalDialogContext } from '@podcastsoundboard/ps-lib/context/GlobalDialogContext'
import React, { Ref, forwardRef } from 'react'

// Generic interface that supports additional props with flexible component type
interface AccessibleComponentProps<T extends { disabled?: boolean }> {
  component: React.ComponentType<T> // Component to render
  componentProps: T // Props specific to the component
  children?: React.ReactNode // Support for children elements
  [key: string]: unknown // Allow additional props
}

// Component to apply disabled logic and allow additional props
const AccessibleComponent = forwardRef(
  <T extends { disabled?: boolean }>(
    {
      component: Component,
      componentProps,
      children, // Ensure children is properly typed
      ...restProps
    }: AccessibleComponentProps<T>,
    ref: Ref<unknown>,
  ) => {
    const { anyDialogOpen, openDialogLabels } = useGlobalDialogContext()
    const { dialogTitle } = useDialogContext()

    const isInOpenDialog =
      dialogTitle !== null && openDialogLabels.includes(dialogTitle)

    const alteredProps = {
      ...componentProps,
      disabled: anyDialogOpen && !isInOpenDialog, // Logic for setting disabled
      tabIndex: -1,
    }

    if (!children)
      return <Component {...restProps} {...alteredProps} ref={ref} />

    return (
      <Component {...restProps} {...alteredProps} ref={ref}>
        {children} {/* Render children only if they exist */}
      </Component>
    )
  },
)

export default AccessibleComponent
