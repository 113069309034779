import React from "react"
import { useNavigate } from "react-router"

import { AxiosError } from "axios"

import DownloadIcon from "@mui/icons-material/Download"
import PublicIcon from "@mui/icons-material/Public"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import LoadingButton from "@mui/lab/LoadingButton"
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"
import FullPageContentContainer from "../../../../components/FullPageContentContainer"
import { redirectToCheckout } from "../../../../lib/stripe"
import { useAppDispatch, useAppSelector } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"

const WindowsContent: React.FC = () => {
  const navigate = useNavigate()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const [submitting, setSubmitting] = React.useState(false)
  const dispatch = useAppDispatch()

  const handleCheckoutClick = React.useCallback(async () => {
    setSubmitting(true)
    try {
      await redirectToCheckout({ sku: "windows" })
    } catch (_err) {
      const err = _err as AxiosError
      setSubmitting(false)
      console.error("Error creating checkout session", err)
      const text =
        (err.response && err.response.data && err.response.data.error) ||
        "Failed to create checkout session."
      navigate("/app/account/windows-licenses")
      dispatch(addSnackbar({ text }))
    }
  }, [dispatch, navigate])

  const isSmallScreen = useMediaQuery("(max-width: 640px)")

  return (
    <Box className="hero" sx={{ pb: 10 }}>
      <Toolbar />

      <FullPageContentContainer minHeight="auto" maxWidth="xl">
        <Stack
          direction="row"
          gap="1rem"
          alignItems="center"
          flexWrap={isSmallScreen ? "wrap" : "nowrap"}
          justifyContent="center"
        >
          <Paper sx={{ p: 4, borderRadius: "20px" }} elevation={4}>
            <Stack
              direction="column"
              spacing={3}
              justifyContent="center"
              alignItems="flex-start"
            >
              <Typography
                variant="body1"
                align="left"
                color="text.secondary"
                fontWeight={800}
                sx={{ pt: 2, mb: 1 }}
              >
                Desktop App
              </Typography>
              <Typography
                component="h2"
                variant="h3"
                align="left"
                color="text.primary"
                sx={{ mt: 0, pb: 2 }}
              >
                Podcast Soundboard for Windows
              </Typography>

              <Stack direction="row" spacing={2}>
                <LoadingButton
                  // style={{ borderRadius: "20px" }}
                  size="large"
                  color="info"
                  onClick={() => handleCheckoutClick()}
                  variant="contained"
                  loading={submitting}
                  startIcon={<ShoppingCartIcon />}
                >
                  Purchase
                </LoadingButton>
              </Stack>
            </Stack>

            <Typography
              variant="body1"
              align="left"
              color="text.primary"
              sx={{ pb: 4, mt: 4 }}
              fontSize={"1.2rem"}
            >
              Podcast Soundboard for Windows is the perfect soundbite and track
              playing tool for those who need something offline.
            </Typography>

            <Stack direction="row" gap="0.5rem" flexWrap="wrap" sx={{ mt: 4 }}>
              <Chip
                size="medium"
                clickable
                color="info"
                onClick={() => navigate("/app/windows#downloads")}
                label="Download"
                icon={<DownloadIcon />}
              />
              <Chip
                size="medium"
                clickable
                color="primary"
                onClick={() =>
                  currentUserProfile
                    ? navigate("/app/account/subscription")
                    : navigate("/app/signup")
                }
                label="Web"
                icon={<PublicIcon />}
              />

              <Chip
                size="medium"
                clickable
                onClick={() => navigate("/app/windows/changelog")}
                label="Changelog"
              />
            </Stack>
          </Paper>

          <Box sx={{ p: 2 }}>
            <img
              style={{
                maxHeight: "40vh",
                maxWidth: isSmallScreen ? "80vw" : "40vw",
              }}
              src={
                darkModeEnabled
                  ? "/img/devices/laptop-dark.png"
                  : "/img/devices/laptop-light.png"
              }
              alt={
                darkModeEnabled ? "Dark mode on mobile" : "Light mode on mobile"
              }
            />
          </Box>
        </Stack>
      </FullPageContentContainer>
    </Box>
  )
}

export default WindowsContent
