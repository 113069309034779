import { Chip, Grid, Stack, Typography } from "@mui/material"

import HomeCard from "./HomeCard"

export default function VoicyIntegration() {
  // const navigate = useNavigate()

  const Voicy = (
    <Chip
      label="Voicy"
      clickable
      onClick={() => window.open("https://voicy.network", "_blank")}
      icon={
        <img
          alt=""
          role="presentation"
          src="/img/voicy-logo.svg"
          style={{ height: "80%" }}
        />
      }
    />
  )
  const Freesound = (
    <Chip
      label="Freesound"
      clickable
      onClick={() => window.open("https://freesound.org", "_blank")}
      icon={
        <img
          alt=""
          role="presentation"
          src="/img/freesound-logo.png"
          style={{ height: "80%", borderRadius: "50%" }}
        />
      }
    />
  )

  return (
    <Grid container spacing={4}>
      {/* <Grid item xs={12}>
        <HomeCard borderRadius={null}>
          <Stack
            height="100%"
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={4}
          >
            <Typography
              variant="h2"
              fontSize="2.2rem"
              fontWeight={800}
              textAlign="center"
            >
              Search from a universe of clips
            </Typography>
            <Stack direction="row" gap="1rem">
              {Voicy}
              {Freesound}
            </Stack>

            <Typography variant="body1" fontSize="1" textAlign="center">
              We integrate with the largest free sound libraries on the web.
            </Typography>
          </Stack>
        </HomeCard>
      </Grid> */}
      <Grid item xs={12} sm={6}>
        <HomeCard variant="black">
          <Stack
            height="100%"
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={4}
          >
            {Voicy}
            <img
              alt=""
              role="presentation"
              src="/img/voicy-logo.svg"
              style={{ height: "7rem" }}
            />
            <Typography variant="body1" fontSize="1rem" textAlign="center">
              Voicy is a user-driven library with over 500,000 trending clips
              and memes
            </Typography>
          </Stack>
        </HomeCard>
      </Grid>
      <Grid item xs={12} sm={6}>
        <HomeCard variant="black">
          <Stack
            height="100%"
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={4}
          >
            {Freesound}
            <img
              alt=""
              role="presentation"
              src="/img/freesound-logo.png"
              onClick={() => window.open("https://freesound.org", "_blank")}
              style={{ height: "7rem", borderRadius: "50%" }}
            />
            <Typography variant="body1" fontSize="1rem" textAlign="center">
              Freesound offers more than 600,000 creative-commons sounds in
              every possible category.
            </Typography>
          </Stack>
        </HomeCard>
      </Grid>
    </Grid>
  )
}
