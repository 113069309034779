import * as React from "react"

import updateSoundboard from "@podcastsoundboard/ps-lib/api/soundboards/update"

import DeleteIcon from "@mui/icons-material/Delete"
import PublicIcon from "@mui/icons-material/Public"
import VolumeDown from "@mui/icons-material/VolumeDown"
import VolumeUp from "@mui/icons-material/VolumeUp"
import LoadingButton from "@mui/lab/LoadingButton"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControlLabel from "@mui/material/FormControlLabel"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import Slider from "@mui/material/Slider"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import deleteSoundboard from "../../api/soundboards/delete"
import { useAppDispatch, useAppSelector } from "../../redux"
import { addSnackbar } from "../../redux/snackbars"
import { Soundboard } from "../../types/Soundboard"

interface EditSoundboardModalProps {
  open: boolean
  soundboard: Soundboard
  handleClose: () => void
  onUpdate?: () => void
}

const EditSoundboardModal: React.FC<EditSoundboardModalProps> = ({
  open,
  soundboard,
  handleClose,
  onUpdate,
}) => {
  const dispatch = useAppDispatch()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const [submitting, setSubmitting] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)
  const [solo, setSolo] = React.useState(false)
  const [makePublic, setMakePublic] = React.useState(false)
  const [description, setDescription] = React.useState("")
  const [featured, setFeatured] = React.useState(false)
  const [requestFeatured, setRequestFeatured] = React.useState(false)
  const [volume, setVolume] = React.useState<number>(1)
  const [deleteAreYouSureOpen, setDeleteAreYouSureOpen] = React.useState(false)

  const [name, setName] = React.useState("")

  const deleteAction = async () => {
    try {
      setDeleting(true)
      await deleteSoundboard(soundboard.uuid)
      setDeleting(false)
      dispatch(
        addSnackbar({
          text: "Deleted soundboard.",
        }),
      )
      onUpdate && onUpdate()
    } catch (err) {
      console.error(err)
      setDeleting(false)
      dispatch(
        addSnackbar({
          text: "Failed to delete soundboard.",
        }),
      )
    } finally {
      setDeleteAreYouSureOpen(false)
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    setSubmitting(true)

    try {
      await updateSoundboard(soundboard.uuid, {
        name,
        solo,
        volume,
        public: makePublic,
        description,
        featured,
        requestFeatured,
      })
      dispatch(
        addSnackbar({
          text: "Updated soundboard.",
        }),
      )
      onUpdate && onUpdate()
    } catch (err) {
      console.error(err)
      dispatch(
        addSnackbar({
          text: "Failed to update soundboard.",
        }),
      )
    } finally {
      setSubmitting(false)
    }
  }

  const receiveSoundboard = (soundboard: Soundboard) => {
    setName(soundboard.name)
    setDescription(soundboard.description || "")
    setMakePublic(Boolean(soundboard.public))
    setFeatured(Boolean(soundboard.featured))
    setRequestFeatured(Boolean(soundboard.requestFeatured))
    setSolo(soundboard.solo || false)
    setVolume(soundboard.volume === null ? 1 : soundboard.volume || 1)
  }

  React.useEffect(() => {
    soundboard && receiveSoundboard(soundboard)
  }, [soundboard])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <DialogTitle id="alert-dialog-title">Edit Soundboard</DialogTitle>

        <IconButton
          onClick={() => setDeleteAreYouSureOpen(true)}
          sx={{ my: 1, mx: 2 }}
          aria-label="back"
        >
          <DeleteIcon style={{ height: 30, width: 30 }} />
        </IconButton>

        <Dialog
          open={deleteAreYouSureOpen}
          onClose={() => setDeleteAreYouSureOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to delete this soundboard?
          </DialogTitle>
          <DialogActions>
            <LoadingButton
              loading={deleting}
              onClick={deleteAction}
              variant="contained"
            >
              Delete
            </LoadingButton>
            <LoadingButton onClick={() => setDeleteAreYouSureOpen(false)}>
              Cancel
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Box>

      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          flexWrap="wrap"
        >
          <Box sx={{ mt: 1, mb: 2 }} style={{ width: "100%" }}>
            <TextField
              name="name"
              required
              id="name"
              fullWidth
              value={name}
              label="Name"
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box sx={{ mt: 1, mb: 2 }} style={{ width: "100%" }}>
            <TextField
              name="description"
              id="description"
              multiline
              fullWidth
              maxRows={5}
              value={description}
              label="Description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Box>

          <Box style={{ width: "100%" }} sx={{ mb: 2 }}>
            <Tooltip title="Pause all other sounds when a sound plays">
              <FormControlLabel
                sx={{ pt: 1 }}
                label="One sound at a time?"
                control={
                  <Checkbox
                    checked={solo}
                    onChange={(e) => setSolo(e.target.checked)}
                  />
                }
              />
            </Tooltip>
          </Box>

          <Box style={{ width: "100%" }}>
            <Typography variant="body1" color="text.secondary">
              Soundboard volume
            </Typography>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <VolumeDown />
              <Slider
                min={0}
                max={1}
                step={0.01}
                name="Volume"
                value={volume || 1}
                onChange={(_e, v) => setVolume(Number(v))}
                valueLabelDisplay="auto"
              />
              <VolumeUp />
            </Stack>
          </Box>

          <Alert
            variant="outlined"
            severity="info"
            sx={{ mt: 2 }}
            icon={<PublicIcon />}
          >
            <AlertTitle>Share your soundboard</AlertTitle>

            <Typography color="inherit" variant="body2">
              {!soundboard.public ? (
                <>
                  <b>Enable public mode</b> to make this soundboard public at
                  the URL:
                </>
              ) : (
                <>The unique URL for your soundboard is:</>
              )}{" "}
              <Link
                href={`https://podcastsoundboard.com/app/psb/${soundboard.uuid}`}
              >
                https://podcastsoundboard.com/app/psb/
                {soundboard.uuid}
              </Link>
            </Typography>

            <Box style={{ width: "100%" }}>
              <FormControlLabel
                label={
                  <Typography variant="body2" color="text.primary">
                    <b>Enable public mode</b> to make your soundboard's link
                    publicly accessible so others can use your soundboard.
                  </Typography>
                }
                sx={{ pt: 1 }}
                control={
                  <Checkbox
                    size="small"
                    checked={makePublic}
                    color="info"
                    onChange={(e) => setMakePublic(e.target.checked)}
                  />
                }
              />
            </Box>

            <Box style={{ width: "100%" }}>
              <FormControlLabel
                label={
                  <Typography variant="body2" color="text.primary">
                    <b>Request publication</b> and our team will consider
                    copying this soundboard into our official soundboard gallery
                    (coming soon).
                  </Typography>
                }
                sx={{ pt: 1 }}
                control={
                  <Checkbox
                    size="small"
                    color="info"
                    checked={requestFeatured}
                    onChange={(e) => setRequestFeatured(e.target.checked)}
                  />
                }
              />
            </Box>
          </Alert>

          {currentUserProfile && currentUserProfile.isAdmin && (
            <Alert variant="outlined" severity="warning" sx={{ mt: 2 }}>
              <AlertTitle>Admin Only - Publish soundboard</AlertTitle>
              <Box style={{ width: "100%" }}>
                <FormControlLabel
                  label={
                    <Typography variant="body2" color="text.secondary">
                      <b>Feature this soundboard</b> to show it in the public
                      soundboards gallery.
                    </Typography>
                  }
                  sx={{ pt: 1 }}
                  control={
                    <Checkbox
                      size="small"
                      color="warning"
                      checked={featured}
                      onChange={(e) => setFeatured(e.target.checked)}
                    />
                  }
                />
              </Box>
            </Alert>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          disabled={!name}
          loading={submitting}
          onClick={handleSubmit}
          variant="contained"
        >
          Save
        </LoadingButton>
        <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default EditSoundboardModal
