import getDurationForAudioWithBounds from "../../helpers/audio/getDurationForAudioWithBounds"
import getProgressDecimalForAudioWithBounds from "../../helpers/audio/getProgressDecimalForAudioWithBounds"
import { Soundboard } from "../../types/Soundboard"
import { startAudioSession } from "../webMediaSession"
import AudioStack from "./AudioStack"
import Sound from "./Sound"

export default class AudioStackInterface {
  soundboardId: string
  padUuid: string
  audioStack: AudioStack

  constructor(audioStack: AudioStack, soundboardId: string, padUuid: string) {
    this.soundboardId = soundboardId
    this.padUuid = padUuid
    this.audioStack = audioStack
    audioStack.registerSoundboardPad(soundboardId, padUuid)
  }

  /************
   * Private
   ************/

  currentAudio(): Sound | undefined {
    const { soundboardId, padUuid } = this
    return this.audioStack.currentAudioForSoundboardPad(soundboardId, padUuid)
  }

  /************
   * Getters
   ************/

  getActualDuration(
    startTime: number | null,
    endTime: number | null,
  ): number | null {
    const audio = this.currentAudio()
    if (!audio) return null

    const rawDuration = audio.duration() || null
    if (rawDuration === null) return null
    if (!isFinite(rawDuration)) return null

    return getDurationForAudioWithBounds(rawDuration, startTime, endTime)
  }

  getProgressDecimalWithBounds(
    startTime: number | null,
    endTime: number | null,
  ): number | null {
    const audio = this.currentAudio()
    if (!audio) return null

    return getProgressDecimalForAudioWithBounds(audio, startTime, endTime)
  }

  getPlaying(): boolean | null {
    const audio = this.currentAudio()
    if (!audio) return null
    return audio.playing()
  }

  getVolume(): number | null {
    const audio = this.currentAudio()
    if (!audio) return null
    return audio.volume()
  }

  getSource(): string | null {
    const audio = this.currentAudio()
    if (!audio) return null
    return audio.src || ""
  }

  getCurrentTime(): number | null {
    const audio = this.currentAudio()
    if (!audio) return null
    return audio.seek()
  }

  /************
   * Simple actions
   ************/

  setProgress(progress: number): void {
    const audio = this.currentAudio()
    if (!audio) return
    if (audio.seek() === progress) return
    audio.seek(progress)
  }

  setLoop(loop: boolean): void {
    const audio = this.currentAudio()
    if (!audio) return
    audio.loop(loop)
  }

  play({ fromTime }: { fromTime?: number } = {}): void {
    const audio = this.currentAudio()
    if (!audio) throw new Error("No audio file.")

    startAudioSession()

    if (fromTime === 0 || (fromTime && !isNaN(fromTime))) audio.seek(fromTime)
    try {
      audio.playAndResetTimers()
    } catch (err) {
      console.error("Error playing audio:", err)
    }
  }

  pause(): void {
    const { soundboardId, padUuid } = this
    this.audioStack.pauseAllForSoundboardPad(soundboardId, padUuid)
  }

  pauseWithFade(): void {
    const { soundboardId, padUuid } = this
    this.audioStack.pauseWithFadeAllForSoundboardLetter(soundboardId, padUuid)
  }

  /************
   * Complex actions
   ************/

  pushMultishotAudio({
    src,
    overrideSoundboard,
    loop,
  }: {
    src: string
    overrideSoundboard?: Soundboard
    loop: boolean
  }): void {
    const { soundboardId, padUuid } = this

    const audio = new Sound({
      src,
      soundboardUuid: soundboardId,
      padUuid,
      overrideSoundboard,
      loop,
    })
    audio.playAndResetTimers()
    this.audioStack.pushNewAudioTrackForSoundboardPad(
      soundboardId,
      padUuid,
      audio,
    )
  }

  pushNewAudioTrack(audio: Sound): void {
    const { soundboardId, padUuid } = this
    this.audioStack.pushNewAudioTrackForSoundboardPad(
      soundboardId,
      padUuid,
      audio,
    )
  }

  pauseAllOtherPads(): void {
    const { soundboardId, padUuid } = this
    this.audioStack.pauseAllOtherPads(soundboardId, padUuid)
  }
}
