import Color from "color"

import Box from "@mui/material/Box"
import Tooltip from "@mui/material/Tooltip"

import { lightTheme } from "../../../../../lib/muiTheme"

const DEFAULT_COLOR = lightTheme.palette.primary.main
const HEIGHT = "0.25rem"

export default function VolumeBar({
  color,
  currentVolume,
}: {
  color: string
  currentVolume: number
}) {
  return (
    <Tooltip title="Current volume" sx={{ color: "white", flexShrink: 0 }}>
      <Box
        style={{
          height: HEIGHT,
          position: "relative",
        }}
      >
        <div
          style={{
            backgroundColor: Color(color || DEFAULT_COLOR)
              .darken(0.2)
              .hex(),
            height: HEIGHT,
            position: "absolute",
            top: 0,
            left: 0,
            width: `${(currentVolume || 0) * 100}%`,
          }}
        />
      </Box>
    </Tooltip>
  )
}
