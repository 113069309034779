import { Paper } from "@mui/material"

import { useAppSelector } from "../../../../redux"

export default function HomeCard(props: {
  children: React.ReactNode
  containerClassName?: string
  variant?: "black" | undefined
  borderRadius?: string | null
}) {
  const {
    variant = "gradient",
    children,
    containerClassName,
    borderRadius,
  } = props
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  return (
    <Paper
      sx={{
        padding: 2,
        height: "100%",
        borderRadius: borderRadius ? "20px" : undefined,
        minHeight: "20vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch",

        ...(variant == "black"
          ? { borderColor: darkModeEnabled ? "white" : "black" }
          : {}),
      }}
      elevation={4}
      className={containerClassName}
    >
      {children}
    </Paper>
  )
}
