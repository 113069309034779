import React, { useEffect } from "react"
import { useNavigate } from "react-router"

import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import LaptopIcon from "@mui/icons-material/Laptop"
import CreatedAtChip from "@podcastsoundboard/ps-lib/components/CreatedAtChip/index"

import HeadsetMicIcon from "@mui/icons-material/HeadsetMic"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium"
import LoadingButton from "@mui/lab/LoadingButton"
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
} from "@mui/material"
import Alert from "@mui/material/Alert"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import DownloadIcon from "@mui/icons-material/Download"
import FilterList from "@mui/icons-material/FilterList"
import searchWindowsLicenses, {
  SearchWindowsLicensesResponse,
} from "../../../../api/windowsLicenses/search"
import FilterContainer from "../../../../components/FilterContainer"
import HeadingContainer from "../../../../components/HeadingContainer"
import muiTheme from "../../../../lib/muiTheme"
import { redirectToCheckout } from "../../../../lib/stripe"
import {
  useAppDispatch,
  // useAppDispatch,
  useAppSelector,
} from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"
import WindowsLicense from "../../../../types/WindowsLicense"

interface WindowsLicenseWithFromWebSubscription extends WindowsLicense {
  fromWebSubscription?: boolean
}

export default function WindowsLicenses({ asAdmin }: { asAdmin?: boolean }) {
  // const dispatch = useAppDispatch()f
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  const [submitting, setSubmitting] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState("")
  const [requestFeatured, setRequestFeatured] = React.useState(false)
  const [featured, setFeatured] = React.useState(false)

  const {
    data: licensesData,
    error: fetchError,
    isFetching,
    // refetch,
  } = useQuery<
    SearchWindowsLicensesResponse | Record<string, never>,
    AxiosError
  >({
    queryKey: ["windowsLicenses"],
    queryFn: async () => {
      if (!currentUserProfile) return {}
      return await searchWindowsLicenses({
        mine: !asAdmin,
        featured,
        requestFeatured,
      })
    },
  })

  const { windowsLicenses } = licensesData || { windowsLicenses: [] }

  useEffect(() => {
    if (fetchError) {
      setErrorMessage(fetchError?.message || "")
    }
  }, [fetchError])

  const windowsLicensesWithUserLicense: WindowsLicenseWithFromWebSubscription[] =
    [
      ...(!asAdmin && currentUserProfile?.hasActiveStripeSubscription
        ? [
            {
              uuid: "user",
              licenseKey: currentUserProfile.personalWindowsLicenseKey,
              userUuid: currentUserProfile.uuid,
              createdAt: currentUserProfile.createdAt,
              updatedAt: currentUserProfile.createdAt,
              isDisabled: false,
              fromWebSubscription: true,
            },
          ]
        : []),
      ...(windowsLicenses || []),
    ]

  const hasLicenseKey =
    !isFetching && windowsLicensesWithUserLicense.length !== 0

  const handleCheckoutClick = React.useCallback(async () => {
    setSubmitting(true)
    try {
      await redirectToCheckout({ sku: "windows" })
    } catch (_err) {
      const err = _err as AxiosError
      setSubmitting(false)
      console.error("Error creating checkout session", err)
      const text =
        (err.response && err.response.data && err.response.data.error) ||
        "Failed to create checkout session."
      navigate("/app/account/windows-licenses")
      dispatch(addSnackbar({ text }))
    }
  }, [dispatch, navigate])

  return (
    <>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>
      )}

      {!asAdmin && currentUserProfile?.hasActiveStripeSubscription && (
        <Alert severity="info">
          As a web subscriber, you are granted a Windows license key for the
          duration of your subscription.
        </Alert>
      )}
      <HeadingContainer sx={{ mb: 4 }}>
        <Typography component="h1" variant="h6" color="textPrimary" noWrap>
          Windows License
        </Typography>

        <LoadingButton
          variant="contained"
          onClick={() => navigate("/app/windows#downloads")}
          color="info"
          startIcon={<DownloadIcon />}
        >
          Download
        </LoadingButton>
      </HeadingContainer>

      {asAdmin && (
        <>
          <FilterContainer>
            <FilterList
              sx={{ color: muiTheme.palette.text.primary, fontSize: 22 }}
            />
            <Typography
              variant="body1"
              color="textPrimary"
              style={{
                fontSize: "13px",
                paddingLeft: "0.15rem",
                paddingRight: "1rem",
              }}
            >
              Filter
            </Typography>

            <FormControlLabel
              color="text.primary"
              control={
                <Checkbox
                  checked={featured}
                  onChange={(e) => setFeatured(e.target.checked)}
                />
              }
              label="Subscribed"
            />
            <FormControlLabel
              color="text.primary"
              control={
                <Checkbox
                  checked={requestFeatured}
                  onChange={(e) => setRequestFeatured(e.target.checked)}
                />
              }
              label="Subscribed"
            />
          </FilterContainer>

          <Divider sx={{ mb: 2 }} />
        </>
      )}

      <Container maxWidth="sm">
        {isFetching && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress style={{ color: "white" }} />
          </Box>
        )}

        {!asAdmin &&
          !isFetching &&
          windowsLicensesWithUserLicense.length === 0 && (
            <Paper sx={{ p: 4, pb: 2 }} elevation={4}>
              <Typography variant="h2" fontSize="2rem" fontWeight="bold">
                Unlock the Windows app with a low-cost lifetime license
              </Typography>
              <List sx={{ width: "100%", pr: 2, my: 2 }}>
                <ListItem sx={{ px: 0 }}>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                      <LaptopIcon color="info" style={{ fontSize: "2rem" }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ color: "text.primary", mb: 2 }}
                    primary="A fully offline experience"
                    secondary="When you can't rely on your network, use the desktop or mobile apps to continue offline."
                  />
                </ListItem>
                <ListItem sx={{ px: 0 }}>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                      <HeadsetMicIcon
                        color="info"
                        style={{ fontSize: "2rem" }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ color: "text.primary" }}
                    primary="Unique features not possible on web"
                    secondary="The desktop app is capable of routing audio to a specific output device, and sounds can be triggered from the background using global shortcuts."
                  />
                </ListItem>
                <ListItem sx={{ px: 0 }}>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                      <WorkspacePremiumIcon
                        color="info"
                        style={{ fontSize: "2rem" }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ color: "text.primary" }}
                    primary="30-day money-back guarantee"
                    secondary={
                      <>
                        If you try the app and it isn't for you, just reach out
                        at{" "}
                        <Link href="/app/contact" color="primary">
                          https://podcastsoundboard.com/app/contact
                        </Link>
                        .
                      </>
                    }
                  />
                </ListItem>
              </List>

              <Box sx={{ mb: 2 }}>
                <LoadingButton
                  variant="contained"
                  startIcon={<ShoppingCartIcon />}
                  fullWidth
                  loading={submitting}
                  onClick={() => handleCheckoutClick()}
                >
                  $14.95 Lifetime License
                </LoadingButton>
              </Box>
            </Paper>
          )}
      </Container>

      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {!isFetching &&
            windowsLicensesWithUserLicense.map((license) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={license.uuid}>
                <Paper sx={{ p: 2 }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={"flex-start"}
                    spacing={2}
                    sx={{ mb: 2 }}
                  >
                    <img
                      style={{ height: "1.8rem" }}
                      alt="Windows"
                      src={
                        darkModeEnabled
                          ? "/img/windows.svg"
                          : "/img/windows-black.svg"
                      }
                    />
                    <Typography variant="h6">Windows License</Typography>
                  </Stack>
                  <Typography variant="body2" color="text.secondary">
                    License key:
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    {license.licenseKey}
                  </Typography>
                  {asAdmin && (
                    <>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mt: 1 }}
                      >
                        Email:
                      </Typography>
                      <Typography variant="body2" color="text.primary">
                        {license.user?.email}
                      </Typography>
                    </>
                  )}
                  <Stack
                    direction="row"
                    gap="0.5rem"
                    sx={{ mt: 2 }}
                    flexWrap="wrap"
                    alignItems={"flex-start"}
                    justifyContent={"flex-start"}
                  >
                    <CreatedAtChip createdAt={license.createdAt} />

                    {license.fromWebSubscription && (
                      <Tooltip title="This license key comes with your web subscription">
                        <Chip
                          sx={{ mt: 0 }}
                          size="small"
                          label="Web subscription"
                          color="info"
                          variant="filled"
                        />
                      </Tooltip>
                    )}
                  </Stack>

                  <Alert severity="warning" sx={{ mt: 2 }}>
                    v2.12.0 and above only
                  </Alert>
                </Paper>
              </Grid>
            ))}
          {hasLicenseKey && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LoadingButton
                variant="contained"
                startIcon={<ShoppingCartIcon />}
                loading={submitting}
                onClick={() => handleCheckoutClick()}
              >
                Add Another License
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  )
}
