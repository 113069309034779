import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown"
import VolumeDown from "@mui/icons-material/VolumeDown"
import VolumeUp from "@mui/icons-material/VolumeUp"
import { Button } from "@mui/material"
import Box from "@mui/material/Box"
// Adjust the import path according to your project structure
// import Button from '@mui/material/Button';
// import Link from '@mui/material/Link';
import Container from "@mui/material/Container"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import Paper from "@mui/material/Paper"
import Slider from "@mui/material/Slider"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import HeadingContainer from "@podcastsoundboard/ps-lib/components/HeadingContainer"
// Assuming RootState is the type of your entire Redux state
import { useAppDispatch, useAppSelector } from "../../../../redux"
import { setDuckPercentage } from "../../../../redux/duckPercentage"
import { setIsDucked } from "../../../../redux/isDucked"
import { setMasterVolume } from "../../../../redux/masterVolume"

export default function Settings(): JSX.Element {
  const dispatch = useAppDispatch()
  const duckPercentage = useAppSelector((state) => state.duckPercentage)
  const isDucked = useAppSelector((state) => state.isDucked)
  const masterVolume = useAppSelector((state) => state.masterVolume)

  return (
    <>
      <HeadingContainer sx={{ mb: 4 }}>
        <Typography component="h1" variant="h6" color="textPrimary" noWrap>
          Global Settings
        </Typography>
      </HeadingContainer>

      <Container maxWidth="md" sx={{ mb: 2, pb: 2 }}>
        <Paper sx={{ p: 2, pb: 1, mb: 2 }} elevation={4}>
          <Typography color="textPrimary" variant="h5" sx={{ pb: 2 }}>
            Master Volume
          </Typography>

          <Box style={{ width: "100%" }}>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <VolumeDown />
              <Slider
                min={0}
                max={1}
                step={0.01}
                name="Volume"
                value={masterVolume}
                onChange={(_e, newValue: number | number[]) =>
                  dispatch(setMasterVolume(newValue as number))
                }
                valueLabelDisplay="auto"
              />
              <VolumeUp />
            </Stack>
          </Box>

          <Typography variant="body1">
            Master volume is {Math.round(masterVolume * 100)}%.
          </Typography>
        </Paper>

        <Paper sx={{ p: 2, mb: 2 }} elevation={4}>
          <Typography color="textPrimary" variant="h5" sx={{ pb: 2 }}>
            Duck Amount
          </Typography>

          <Box style={{ width: "100%" }}>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
            >
              <Tooltip
                title={isDucked ? "Duck enabled" : "Amount to duck audio"}
              >
                <IconButton
                  onClick={() => dispatch(setIsDucked(!isDucked))}
                  sx={{ ml: -1 }}
                >
                  <KeyboardDoubleArrowDownIcon
                    color={isDucked ? "primary" : undefined}
                  />
                </IconButton>
              </Tooltip>
              <Slider
                min={0}
                max={100}
                step={1}
                name="Duck percentage"
                value={duckPercentage}
                onChange={(_e, newValue: number | number[]) =>
                  dispatch(setDuckPercentage(newValue as number))
                }
                valueLabelDisplay="auto"
              />
              <VolumeUp style={{ opacity: 0 }} />
            </Stack>
          </Box>

          <Typography variant="body1">
            Audio will be reduced {Math.round(duckPercentage)}% when ducked.
          </Typography>
        </Paper>
        <Paper sx={{ p: 2 }} elevation={4}>
          <Typography color="textPrimary" variant="h5" sx={{ pb: 2 }}>
            Freesound Account
          </Typography>

          <Box style={{ width: "100%" }}>
            <Typography sx={{ mb: 2 }}>
              To import sounds from Freesound, you must link your Freesound
              account. Create a freesound account at{" "}
              <Link href="https://freesound.org" target="_blank">
                freesound.org
              </Link>
              .
            </Typography>

            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                window.open(
                  `/api/v1/auth/freesound?state=${encodeURIComponent(
                    window.location.pathname,
                  )}`,
                  "_blank",
                )
              }}
            >
              Link Freesound account
            </Button>
          </Box>
        </Paper>
      </Container>
    </>
  )
}
