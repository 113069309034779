import { ReactNode } from 'react'

import Paper from '@mui/material/Paper'

import { SxProps } from '@mui/material'
import { LIGHT_THEME_BACKGROUND } from '../../colors'

const PageContainer = ({
  children,
  darkModeEnabled,
  sx,
  style: styleOverrides,
}: {
  children?: ReactNode
  darkModeEnabled?: boolean
  sx?: SxProps
  style?: React.CSSProperties
}) => {
  const style = {
    minHeight: '100%',
    flexGrow: 1,
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    ...(styleOverrides || {}),
  } as React.CSSProperties
  if (!darkModeEnabled) {
    style.backgroundColor = LIGHT_THEME_BACKGROUND
  }
  return (
    <Paper
      elevation={0}
      style={style}
      sx={sx}
      className={darkModeEnabled ? 'cool-svg-bg-1-dark' : 'cool-svg-bg-1-light'}
    >
      {children}
    </Paper>
  )
}

export default PageContainer
