// prettier-ignore
import { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom"

import { LIGHT_THEME_BACKGROUND } from "@podcastsoundboard/ps-lib/colors"

import Box from "@mui/material/Box"
import { ThemeProvider } from "@mui/material/styles"

import Fallback from "./Fallback"
import ContactFab from "./components/ContactFab"
import CouponCatcher from "./hoc/CouponCatcher"
import FetchUserProfile from "./hoc/FetchUserProfile"
import GoogleAnalyticsReporter from "./hoc/GoogleAnalyticsReporter"
import SSRSkipComponent from "./hoc/SSRSkipComponent"
import ScrollToTop from "./hoc/ScrollToTop"
import { darkTheme, lightTheme } from "./lib/muiTheme"
import Account from "./pages/Account"
import AccountSubscriptions from "./pages/Account/pages/AccountSubscriptions"
import ChangePassword from "./pages/Account/pages/ChangePassword"
import Profile from "./pages/Account/pages/Profile"
import WindowsLicenses from "./pages/Account/pages/WindowsLicenses"
import AdminDashboard from "./pages/AdminDashboard"
import AdminBlogPostManagement from "./pages/AdminDashboard/pages/AdminBlogPostManagement"
import AdminEditUser from "./pages/AdminDashboard/pages/AdminEditUser"
import AdminSoundboardManagement from "./pages/AdminDashboard/pages/AdminSoundboardManagement"
import AdminUserManagement from "./pages/AdminDashboard/pages/AdminUserManagement"
// import Affiliates from "./pages/Affiliates"
import PostList from "./pages/Blog/pages/PostList"
import ShowPost from "./pages/Blog/pages/ShowPost"
import CompleteConfirmEmail from "./pages/CompleteConfirmEmail"
import CompletePasswordReset from "./pages/CompletePasswordReset"
import ConfirmEmail from "./pages/ConfirmEmail"
import Contact from "./pages/Contact"
import ForgotPassword from "./pages/ForgotPassword"
import Home from "./pages/Home"
import MobileChangelog from "./pages/MobileChangelog"
import Desktop from "./pages/Platforms/pages/Desktop"
import Mobile from "./pages/Platforms/pages/Mobile"
import Privacy from "./pages/Privacy"
import RefundPolicy from "./pages/RefundPolicy"
import ShowPublicSoundboard from "./pages/ShowPublicSoundboard"
import SignIn from "./pages/SignIn"
import SignUp from "./pages/SignUp"
import Help from "./pages/Soundboards/pages/Help"
import Settings from "./pages/Soundboards/pages/Settings"
import SoundFileManagement from "./pages/Soundboards/pages/SoundFileManagement"
import SoundboardManagement from "./pages/Soundboards/pages/SoundboardManagement"
import TermsOfUse from "./pages/TermsOfUse"
import Windows from "./pages/Windows"
import WindowsChangelog from "./pages/WindowsChangelog"
import WindowsVersions from "./pages/WindowsVersions"
import { useAppDispatch, useAppSelector } from "./redux"
import { KeyboardProvider } from "@podcastsoundboard/ps-lib/components/KeyboardToggle/KeyboardToggle"
import { setKeyboardDisabled } from "./redux/keyboardDisabled"

const AdminShowSoundboard = lazy(
  () => import("./pages/AdminDashboard/pages/AdminShowSoundboard"),
)
const ShowSoundboard = lazy(
  () => import("./pages/Soundboards/pages/ShowSoundboard"),
)
const AdminEditBlogPost = lazy(
  () => import("./pages/AdminDashboard/pages/AdminEditBlogPost"),
)
const FallbackRedirect = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("/app")
  }, [navigate])
  return <Fallback />
}

export default function Router() {
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const keyboardDisabled = useAppSelector((state) => state.keyboardDisabled)
  const dispatch = useAppDispatch()

  return (
    <FetchUserProfile>
      <GoogleAnalyticsReporter>
        <CouponCatcher>
          <KeyboardProvider
            keyboardDisabled={keyboardDisabled}
            toggleKeyboardDisabled={() =>
              dispatch(setKeyboardDisabled(!keyboardDisabled))
            }
          >
            <ThemeProvider theme={darkModeEnabled ? darkTheme : lightTheme}>
              <Box
                sx={{
                  // backgroundColor: "background.default"
                  backgroundColor: darkModeEnabled
                    ? ""
                    : LIGHT_THEME_BACKGROUND,
                }}
                style={{ minHeight: "100vh", overflow: "hidden" }}
              >
                <ContactFab />
                <ScrollToTop />

                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="app" element={<Home />} />

                  <Route path="app/signin" element={<SignIn />} />
                  <Route path="app/signup" element={<SignUp />} />
                  <Route path="app/contact" element={<Contact />} />
                  <Route path="app/privacy" element={<Privacy />} />
                  <Route path="app/terms" element={<TermsOfUse />} />
                  <Route path="app/refunds" element={<RefundPolicy />} />
                  {/* <Route path="app/affiliates" element={<Affiliates />} /> */}
                  <Route
                    path="app/forgot-password"
                    element={<ForgotPassword />}
                  />
                  <Route
                    path="app/complete-password-reset"
                    element={<CompletePasswordReset />}
                  />
                  <Route path="app/confirm-email" element={<ConfirmEmail />} />
                  <Route
                    path="app/complete-confirm-email"
                    element={<CompleteConfirmEmail />}
                  />

                  <Route path="app/platforms/desktop" element={<Desktop />} />

                  <Route path="app/windows" element={<Windows />} />
                  <Route
                    path="app/windows/downloads"
                    element={<WindowsVersions />}
                  />
                  <Route
                    path="app/windows/changelog"
                    element={<WindowsChangelog />}
                  />

                  <Route
                    path="app/platforms/desktop/changelog"
                    element={<WindowsChangelog />}
                  />
                  <Route path="app/platforms/mobile" element={<Mobile />} />
                  <Route
                    path="app/platforms/mobile/changelog"
                    element={<MobileChangelog />}
                  />

                  <Route path="app/blog" element={<PostList />} />
                  <Route path="app/blog/:blogPostUuid" element={<ShowPost />} />

                  <Route
                    path="app/psb/:soundboardUuid"
                    element={
                      <SSRSkipComponent>
                        <Suspense fallback={<Fallback />}>
                          <ShowPublicSoundboard />
                        </Suspense>
                      </SSRSkipComponent>
                    }
                  />

                  <Route path="app/account/*" element={<Account />}>
                    <Route path="profile" element={<Profile />} />
                    <Route
                      path="change-password"
                      element={<ChangePassword />}
                    />
                    <Route
                      path="subscription"
                      element={<AccountSubscriptions />}
                    />
                    <Route
                      path="subscription/:status"
                      element={<AccountSubscriptions />}
                    />

                    <Route path="help" element={<Help />} />

                    <Route
                      path="management"
                      element={<SoundboardManagement />}
                    />
                    <Route path="sounds" element={<SoundFileManagement />} />
                    <Route path="settings" element={<Settings />} />
                    <Route
                      path="windows-licenses"
                      element={<WindowsLicenses />}
                    />

                    <Route
                      path="soundboards/:soundboardUuid"
                      element={
                        // skip because it checks navigator for mediaDevices
                        <SSRSkipComponent>
                          <Suspense fallback={<Fallback />}>
                            <ShowSoundboard />
                          </Suspense>
                        </SSRSkipComponent>
                      }
                    />
                    <Route path="*" element={<FallbackRedirect />} />
                  </Route>

                  <Route path="app/admin/*" element={<AdminDashboard />}>
                    <Route
                      path="soundboards"
                      element={<AdminSoundboardManagement />}
                    />
                    <Route
                      path="soundboards/:soundboardUuid"
                      element={
                        // skip because it checks navigator for mediaDevices
                        <SSRSkipComponent>
                          <Suspense fallback={<Fallback />}>
                            <AdminShowSoundboard />
                          </Suspense>
                        </SSRSkipComponent>
                      }
                    />
                    <Route
                      path="windows-licenses"
                      element={<WindowsLicenses asAdmin />}
                    />
                    <Route path="users" element={<AdminUserManagement />} />
                    <Route path="users/:userUuid" element={<AdminEditUser />} />
                    <Route
                      path="blogposts"
                      element={<AdminBlogPostManagement />}
                    />

                    <Route
                      path="blogposts/:blogPostUuid"
                      element={
                        <SSRSkipComponent>
                          <Suspense fallback={<Fallback />}>
                            <AdminEditBlogPost />
                          </Suspense>
                        </SSRSkipComponent>
                      }
                    />

                    <Route path="*" element={<FallbackRedirect />} />
                  </Route>

                  <Route path="*" element={<FallbackRedirect />} />
                </Routes>
              </Box>
            </ThemeProvider>
          </KeyboardProvider>
        </CouponCatcher>
      </GoogleAnalyticsReporter>
    </FetchUserProfile>
  )
}
