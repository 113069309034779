import { useNavigate } from "react-router-dom"

import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import FeatureGrid from "../../../../components/FeatureGrid"
import FullPageContentContainer from "../../../../components/FullPageContentContainer"
import { useAppSelector } from "../../../../redux"
import DeviceCards from "../../components/DeviceCards"
import Reviews from "../../components/Reviews"
import TitleCards from "../../components/TitleCards"
import TrustedList from "../../components/TrustedList/TrustedList"
import VoicyIntegration from "../../components/VoicyIntegration"

function SectionHeader({
  title,
  subtitle,
}: {
  title: string
  subtitle?: string
}) {
  return (
    <>
      <Typography
        variant="h4"
        component="h2"
        sx={{ mb: subtitle ? 2 : 6 }}
        color="text.primary"
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="body1"
          component="p"
          sx={{ mb: 6 }}
          color="text.primary"
        >
          {subtitle}
        </Typography>
      )}
    </>
  )
}
export default function HomeContent() {
  const navigate = useNavigate()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  // const [welcomeDialogOpen, setWelcomeDialogOpen] = React.useState(true)

  return (
    <>
      <Box className="hero" sx={{ pb: 10 }}>
        <Toolbar />

        {/* <ThemeProvider theme={lightTheme}>
          <Snackbar
            open={welcomeDialogOpen}
            onClose={() => setWelcomeDialogOpen(false)}
            action={
              <Stack direction="row" gap="0.5rem" alignItems="center">
                <DeviceChips
                  sx={{ pb: 1, pt: 1 }}
                  handleOpenUrl={(url) => window.open(url, "_blank")}
                  navigate={navigate}
                  onlyMobile
                />
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => setWelcomeDialogOpen(false)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Stack>
            }
            autoHideDuration={6000}
            message="Our mobile apps are now free!"
          />
        </ThemeProvider> */}

        <FullPageContentContainer minHeight="auto" maxWidth="lg">
          {/* <Paper elevation={4} sx={{ p: 3, m: 3 }}> */}
          <Box className="home-hero-content">
            <Stack
              direction="column"
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                component="h2"
                variant="h3"
                align="center"
                fontSize="2rem"
                sx={{ pt: 2, pb: 2 }}
                style={{
                  color: darkModeEnabled ? "white" : "#333",
                  opacity: 0.9,
                }}
              >
                Bring every moment to life with sound
              </Typography>
              <Typography
                component="h1"
                variant="h3"
                align="center"
                className="home-title"
                sx={{ pb: 4 }}
                style={{ color: darkModeEnabled ? "white" : "#333" }}
              >
                Your custom soundboard for events and shows
              </Typography>

              <Button
                size="large"
                endIcon={<ChevronRightIcon />}
                onClick={() =>
                  currentUserProfile
                    ? navigate("/app/account/soundboards/new")
                    : navigate("/app/signup")
                }
                variant="contained"
              >
                {currentUserProfile ? "My Soundboards" : "Create a soundboard"}
              </Button>
            </Stack>
          </Box>
        </FullPageContentContainer>

        <FullPageContentContainer minHeight="auto" maxWidth="lg" sx={{ mt: 2 }}>
          <TitleCards />
          {/* <Container maxWidth="lg" sx={{ pt: 12 }}>
            <UsesChips />
          </Container> */}
        </FullPageContentContainer>
      </Box>

      <Container maxWidth="lg" sx={{ mb: 10 }}>
        <SectionHeader
          title="Thousands of happy users"
          subtitle={`
          With nearly a decade of customer collaboration, we've crafted an unbeatable user
          experience with a powerful feature set. Try Podcast Soundboard today, backed by our money-back guarantee.
          `}
        />
        <Reviews />
      </Container>

      <Container maxWidth="lg" sx={{ my: 10 }}>
        <SectionHeader
          title="Premium software at an affordable price"
          subtitle={`
            Find Podcast Soundboard for free on the iOS app store and Android
            Play store. We also have premium desktop versions
            at an affordable price for a lifetime license.
            If you want to try the app from your browser, make a free account!
            `}
        />
        <DeviceCards />
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ px: 2, pt: 2 }}
        >
          <Typography
            variant="subtitle2"
            fontSize="1rem"
            style={{
              opacity: 0.8,
              textAlign: "center",
            }}
          ></Typography>
        </Stack>
      </Container>

      <Container maxWidth="lg" sx={{ my: 10 }}>
        <SectionHeader
          title="Unbeatable features"
          subtitle={`
            Customize your soundboard entirely to your needs. We're always adding new features.
            If you have any ideas, reach out!
            `}
        />
        <FeatureGrid />
      </Container>

      <Container maxWidth="lg" sx={{ my: 10 }}>
        <SectionHeader
          title="Search from a universe of clips"
          subtitle={`
            We integrate with the largest free sound libraries on the web.
            `}
        />
        <VoicyIntegration />
      </Container>

      <Container maxWidth="lg" sx={{ my: 10 }}>
        <SectionHeader
          title="Trusted by creators everywhere"
          subtitle={`
          Years of user-driven refinement have shaped Podcast Soundboard into the essential tool it is today.
          `}
        />
        <TrustedList />
      </Container>
    </>
  )
}
