import React from 'react'

import { Typography } from '@mui/material'
import tinycolor from 'tinycolor2'

const PadTrackName: React.FC<{
  name: string
  color: string
  hasImage: boolean
}> = ({ name, color, hasImage }) => {
  const textColor = hasImage
    ? tinycolor.mostReadable(color, ['#222', '#fff']).toHexString()
    : undefined

  return (
    <Typography
      style={{
        cursor: 'pointer',
        border: hasImage ? `1px solid ${color}` : undefined,
        borderRadius: '0.2rem',
        flexShrink: 1,
        backgroundColor: hasImage ? color : undefined,
        // whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        wordBreak: 'break-all',
        overflow: 'hidden',
        color: textColor,
        lineHeight: 1.25,
      }}
      sx={{ px: hasImage ? 0.5 : 0 }}
      component="div"
      variant="h5"
      fontSize="1rem"
    >
      {name}
    </Typography>
  )
}

export default PadTrackName
