import Helmet from "react-helmet"

import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"

// import Fallback from '../../Fallback'
// import HomeTypist from "./components/HomeTypist"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"

import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import HomeContent from "./components/HomeContent"
import { useAppSelector } from "../../redux"

export default function Home() {
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  return (
    <>
      <Helmet titleTemplate="%s">
        <title>Podcast Soundboard: The Best Custom Soundboard</title>
        <meta
          name="description"
          content="Podcast Soundboard is the best soundboard application, available on all platforms. Featuring many customization and control options and a money-back guarantee."
        />
      </Helmet>

      <PageContainer darkModeEnabled={darkModeEnabled}>
        <Box>
          <AppBar
            showLogo
            title="Podcast Soundboard"
            typographyColorOverride="white"
          />
          <HomeContent />
          <Divider />
          <Footer />
        </Box>
      </PageContainer>
    </>
  )
}
