import { ChipProps } from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import AccessibleChip from '../AccessibleChip'

const SoundboardTopBarButton: React.FC<{
  tooltipTitle: string
  label: string
  color?: ChipProps['color']

  onClick?: () => void
  icon: ChipProps['icon']
}> = (props) => {
  return (
    <Tooltip title={props.tooltipTitle}>
      <AccessibleChip
        color={props.color}
        clickable={Boolean(props.onClick)}
        icon={props.icon}
        onClick={props.onClick}
        aria-label={props.label}
        label={props.label}
      />
    </Tooltip>
  )
}

export default SoundboardTopBarButton
