import navigator from "./navigator"
import window from "./window"

export function startAudioSession() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (navigator && navigator.audioSession)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    navigator.audioSession.type = "play-and-record"
}

// navigator.mediaSession.metadata = new MediaMetadata({
//   title: 'Podcast Soundboard',
//   artwork: [
//     { src: 'path/to/artwork-96x96.jpg', sizes: '96x96', type: 'image/jpeg' },
//     { src: 'path/to/artwork-128x128.jpg', sizes: '128x128', type: 'image/jpeg' },
//   ]
// });

// navigator.mediaSession.setActionHandler('play', () => {
//   // Custom play logic
// });

// navigator.mediaSession.setActionHandler('pause', () => {
//   // Custom pause logic
// });

// Start playback and set Media Session when the app is active
// function startMediaSession() {
//   navigator.mediaSession.playbackState = "playing"
//   // if ('mediaSession' in navigator)
//   //   navigator.mediaSession.playbackState = 'playing'
// }

// End playback and reset Media Session when the app is no longer active
// function endMediaSession() {
//   if ("mediaSession" in navigator) navigator.mediaSession.playbackState = "none"
// }

// Listen for visibility changes
// document.addEventListener('visibilitychange', () => {
//   if (document.visibilityState === 'hidden') {
//     // User has tabbed out or minimized the app
//     endMediaSession()
//   } else {
//     // User has returned to the app
//     startMediaSession()
//   }
// })

export function createAudioContext() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return new (window.AudioContext || window.webkitAudioContext)()
}

// Initial setup when the page loads
// startMediaSession()
