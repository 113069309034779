import * as React from "react"
import { useDrag, useDrop } from "react-dnd"
import Color from "color"
import * as Sentry from "@sentry/react"

import PadInteractiveButtonsRow from "@podcastsoundboard/ps-lib/components/PadInteractiveButtonsRow"
import PadOptionsIndicator from "@podcastsoundboard/ps-lib/components/PadOptionsIndicator"
import PadTrackName from "@podcastsoundboard/ps-lib/components/PadTrackName"

import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import { ThemeProvider } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import moveSoundboardPadToIndex from "../../../../api/soundboards/moveSoundboardPadToIndex"
// import SSRSkipComponent from '../../../../hoc/SSRSkipComponent'
import { NoteMessageEvent } from "webmidi"
import SSRSkipComponent from "../../../../hoc/SSRSkipComponent"
import AudioStackInterface from "../../../../lib/audioStack/AudioStackInterface"
import Sound from "../../../../lib/audioStack/Sound"
import { midiEvents } from "../../../../lib/midiEvents"
import { themesFromPrimary } from "../../../../lib/muiTheme"
import { useAppDispatch, useAppSelector } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"
import { updateSoundboard } from "../../../../redux/soundboards"
import Pad from "../../../../types/Pad"
import { Soundboard } from "../../../../types/Soundboard"
import ProgressBar from "./components/ProgressBar"
import Shortcut from "./components/Shortcut"
import VolumeBar from "./components/VolumeBar"
import { PRIMARY_PURPLE } from "@podcastsoundboard/ps-lib/colors/index"

// don't SSR EditPadModal
const EditPadModal = React.lazy(() => import("../EditPadModal"))

function PadComponent({
  pad,
  solo,
  keyIndex,
  onPadUpdate,
  audioInterface,
  overrideSoundboard, // purely to override soundboard config when it's not in global state (homepage)
  overrideOnCogPress, // to disable configuration options when soundboard config is static (homepage)
  setDragDropSaving,
  disableDragDrop,
}: {
  pad: Pad
  solo: boolean
  keyIndex: number
  onPadUpdate?: () => void
  audioInterface: AudioStackInterface
  overrideSoundboard?: Soundboard
  setDragDropSaving: (saving: boolean) => void
  overrideOnCogPress?: () => void
  disableDragDrop?: boolean
}) {
  const {
    uuid: padUuid,
    soundboardUuid,
    name,
    soundfile,
    loop,
    color,

    startTime,
    endTime,
    shortcut,

    multishot,
    alwaysPlayFromStart,
    midiBinding,

    fadeOutDuration = 0,
    fadeOutOnPressDuration = 0,

    autoFadeInDuration = 0,
    fadeInOnPressDuration = 0,
    coverImageUrl,
  } = pad || {}

  const dispatch = useAppDispatch()

  const fadeOutLastPressedAt = useAppSelector(
    (state) => state.fadeOutLastPressedAt[padUuid],
  )
  const webMidiLoaded = useAppSelector((state) => state.webMidiLoaded)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  const currentMIDIDevice = useAppSelector((state) => state.currentMIDIDevice)
  const keyboardDisabled = useAppSelector((state) => state.keyboardDisabled)

  const [error, setError] = React.useState("")
  const [playing, setPlaying] = React.useState(false)
  const [duration, setDuration] = React.useState<number | null>(null)
  const [loaded, setLoaded] = React.useState(false)
  const [progressDecimal, setProgressDecimal] = React.useState<number | null>(
    null,
  )
  const [editPadModalOpen, setEditPadModalOpen] = React.useState(false)
  const [currentVolume, setCurrentVolume] = React.useState<number>(1)

  const readyToPlay = !error && loaded
  const loading = !loaded && !error

  const handleDrag = React.useCallback(
    async (fromIndex: number) => {
      try {
        setDragDropSaving(true)
        const soundboard = await moveSoundboardPadToIndex(soundboardUuid, {
          fromIndex,
          toIndex: keyIndex,
        })
        dispatch(updateSoundboard(soundboard))
        setDragDropSaving(false)
      } catch (err) {
        setDragDropSaving(false)
        dispatch(addSnackbar({ id: "failToMove", text: "Failed to move pad." }))
      }
    },
    [dispatch, keyIndex, setDragDropSaving, soundboardUuid],
  )

  const [, drag] = useDrag(
    () => ({
      type: "PAD",
      item: { fromIndex: keyIndex },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [keyIndex],
  )

  const [, drop] = useDrop<{ fromIndex: number }>(
    () => ({
      accept: "PAD",
      drop: (item) => handleDrag(item.fromIndex),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [pad.uuid, handleDrag],
  )

  /*
   * Interactions
   */

  // pause
  const pause = React.useCallback(async () => {
    if (fadeOutLastPressedAt) return
    audioInterface.pauseWithFade()
  }, [audioInterface, fadeOutLastPressedAt])

  // play
  const play = React.useCallback(() => {
    if (!soundfile) return
    if (!readyToPlay) return

    if (audioInterface.getPlaying()) {
      if (multishot) {
        const src = soundfile.fileUrl

        audioInterface.pushMultishotAudio({ src, overrideSoundboard, loop })
      } else {
        pause()
      }
    } else {
      if (solo) audioInterface.pauseAllOtherPads()
      audioInterface.play({ fromTime: alwaysPlayFromStart ? 0 : undefined })
    }

    // if (pad && pad.soundfile && pad.soundfile.voicyClipId) {
    //   try {
    //     createVoicySoundView(pad.soundfile.voicyClipId)
    //   } catch (err) {
    //     console.error("Error creating voicy sound view", err)
    //   }
    // }
  }, [
    soundfile,
    readyToPlay,
    audioInterface,
    multishot,
    overrideSoundboard,
    loop,
    pause,
    solo,
    alwaysPlayFromStart,
  ])

  // skipback
  const skipback = React.useCallback(() => {
    audioInterface.pause()
    setPlaying(false)
    audioInterface.setProgress(startTime || 0)
  }, [audioInterface, startTime])

  const handleKeypress = React.useCallback(
    (e: KeyboardEvent) => {
      if (keyboardDisabled || !shortcut) return
      if (e.key.toLowerCase() === shortcut.toLowerCase()) {
        play()
      }
    },
    [keyboardDisabled, shortcut, play],
  )

  const handleMIDIPress = React.useCallback(
    (e: NoteMessageEvent) => {
      const incomingMidiBinding =
        e.note.name + e.note.octave + (e.note.accidental || "")
      if (incomingMidiBinding === midiBinding) play()
    },
    [midiBinding, play],
  )

  /*
   * Periodic actions
   * - remember that these will stop executing if you navigate away
   */

  // sync progress with audio object
  const refreshProgress = React.useCallback(() => {
    const playing = audioInterface.getPlaying()
    if (playing !== null) setPlaying(playing)

    const duration = audioInterface.getActualDuration(startTime, endTime)
    if (duration !== null) setDuration(duration)

    const progressDecimal = audioInterface.getProgressDecimalWithBounds(
      startTime,
      endTime,
    )
    if (progressDecimal !== null) setProgressDecimal(progressDecimal || 0)

    const currVolume = audioInterface.getVolume()
    if (currVolume !== null) setCurrentVolume(currVolume || 1)

    setProgressDecimal(progressDecimal)
  }, [audioInterface, startTime, endTime])

  /*
   * Effects
   */

  // periodically sync progress (20 times a second)
  React.useEffect(() => {
    const intervalId = setInterval(() => refreshProgress(), 40)

    return () => {
      clearInterval(intervalId)
    }
  }, [refreshProgress])

  // initialize audio and handle track changes
  // DANGER: cannot set up new audio device for track
  // without this pad being mounted
  React.useEffect(() => {
    setLoaded(false)
    setError("")
    // don't initialize audio with no sound file
    if (!soundfile || !soundfile.filepath) {
      setError("No track.")
      return
    }

    const audio = audioInterface.currentAudio()
    const newAudioSrc = soundfile.fileUrl

    // don't create new audio when src already correct
    const noNeedToResetAudio =
      audio && audio.src === newAudioSrc && audio.state() === "loaded"
    audioInterface.setLoop(loop)
    if (noNeedToResetAudio) {
      setLoaded(true)
      return
    }

    audioInterface.pause()
    const newAudio = new Sound({
      padUuid,
      soundboardUuid,
      src: newAudioSrc,
      overrideSoundboard,
      loop,
    })
    audioInterface.pushNewAudioTrack(newAudio)

    if (newAudio.state() === "loaded") {
      setLoaded(true)
      return
    }
    newAudio.on("load", () => {
      setLoaded(true)
    })
    newAudio.on("loaderror", (_, error) => {
      console.error(
        "howler.js load error event for file with error",
        newAudioSrc,
        error,
      )
      Sentry.captureException(error)
      setError("")
      setLoaded(true)
    })
  }, [
    audioInterface,
    loop,
    pause,
    overrideSoundboard,
    soundboardUuid,
    soundfile,
    startTime,
    padUuid,
  ])

  // trigger on keyboard press
  React.useEffect(() => {
    document.addEventListener("keydown", handleKeypress)
    return () => document.removeEventListener("keydown", handleKeypress)
  }, [handleKeypress])

  // trigger on midi press
  React.useEffect(() => {
    // MIDI isn't immediately available. partially because
    // we explicitly use a timeout before initializing it, can't remember why.
    if (webMidiLoaded && currentMIDIDevice) {
      try {
        midiEvents.addListener(currentMIDIDevice, handleMIDIPress)
      } catch (err) {
        console.error("Error creating listener for webmidi for letter", err)
      }
    }
    return () => {
      midiEvents.removeListener(currentMIDIDevice, handleMIDIPress)
    }
    // refresh midi binding when we change track or midi binding
  }, [webMidiLoaded, currentMIDIDevice, handleMIDIPress, pad])

  const newColor = React.useMemo(
    () =>
      Color(color || PRIMARY_PURPLE)
        .opaquer(-0.8)
        .rgb()
        .string(),
    [color],
  )

  const reduceTextSize =
    useMediaQuery("(max-width: 640px)") || (name || "").length > 20
  const { darkTheme, lightTheme } = themesFromPrimary(color || PRIMARY_PURPLE)

  return (
    <ThemeProvider theme={darkModeEnabled ? darkTheme : lightTheme}>
      <Box ref={disableDragDrop ? undefined : drop} style={{ height: "100%" }}>
        <Card
          ref={disableDragDrop ? undefined : drag}
          data-pad={pad.uuid}
          elevation={0}
          sx={{ backgroundColor: "background.default" }}
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            position: "relative",
            minHeight: "150px",
            flexDirection: "column",
            border: `2px solid ${color || PRIMARY_PURPLE}`,
            opacity: soundfile ? 1 : 0.5,
            backgroundColor: playing ? newColor : "",
          }}
        >
          <div
            style={{
              backgroundImage: coverImageUrl ? `url('${coverImageUrl}')` : "",
              position: "absolute",
              inset: 0,
              backgroundPosition: "center",
              backgroundSize: "cover",
              zIndex: 0,
              pointerEvents: "none",
              opacity: playing ? 0.5 : 1,
            }}
          />
          <ProgressBar
            color={color}
            duration={duration}
            progressDecimal={Number(progressDecimal)}
            hasTrack={Boolean(soundfile)}
          />

          <VolumeBar color={color} currentVolume={currentVolume} />

          <SSRSkipComponent>
            <EditPadModal
              disabled={Boolean(overrideOnCogPress)}
              pad={pad}
              audioInterface={audioInterface}
              open={editPadModalOpen}
              handleClose={() => setEditPadModalOpen(false)}
              onUpdate={() => {
                setEditPadModalOpen(false)
                onPadUpdate && onPadUpdate()
              }}
            />
          </SSRSkipComponent>

          <Stack
            direction="column"
            height="100%"
            alignItems="flex-start"
            spacing="0.5rem"
            flexGrow={1}
            zIndex={1}
            sx={{ p: 1, cursor: "pointer" }}
            onClick={play}
          >
            <Stack
              alignSelf="stretch"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ cursor: "pointer" }}
            >
              {name && (
                <PadTrackName
                  name={name}
                  color={color}
                  hasImage={Boolean(coverImageUrl)}
                />
              )}
            </Stack>

            <Stack direction="column" justifyContent="flex-start">
              <Stack
                direction="row"
                alignItems="flex-start"
                flexWrap="wrap"
                flexDirection="row"
                style={{ cursor: "pointer", flexGrow: 1 }}
                gap="8px"
              >
                {shortcut && (
                  <Tooltip title="Keyboard shortcut">
                    <Box sx={{ m: 0 }}>
                      <Shortcut
                        color={color}
                        shortcut={shortcut}
                        reduceTextSize={reduceTextSize}
                      />
                    </Box>
                  </Tooltip>
                )}

                {midiBinding && (
                  <Tooltip title="MIDI binding">
                    <Box sx={{ m: 0 }}>
                      <Shortcut
                        color={color}
                        shortcut={midiBinding}
                        reduceTextSize={reduceTextSize}
                        isMidi
                      />
                    </Box>
                  </Tooltip>
                )}

                <PadOptionsIndicator
                  color={color}
                  alwaysPlayFromStart={alwaysPlayFromStart}
                  loop={loop}
                  multishot={multishot}
                  autoFadeInDuration={Boolean(autoFadeInDuration)}
                  fadeOutDuration={Boolean(fadeOutDuration)}
                />
              </Stack>
            </Stack>
          </Stack>

          <PadInteractiveButtonsRow
            skipback={skipback}
            play={play}
            pause={pause}
            onSettingsPress={() =>
              overrideOnCogPress
                ? overrideOnCogPress()
                : setEditPadModalOpen(true)
            }
            readyToPlay={readyToPlay}
            playing={playing}
            loading={loading}
            fadeOutLastPressedAt={fadeOutLastPressedAt}
            name={pad.name}
            hasSoundFile={Boolean(pad.soundfile)}
            color={pad.color}
            multishot={pad.multishot}
            fadeOutOnPressDuration={fadeOutOnPressDuration}
            fadeInOnPressDuration={fadeInOnPressDuration}
          />
        </Card>
      </Box>
    </ThemeProvider>
  )
}

const MemoizedPad = React.memo(PadComponent)

export default MemoizedPad
