import * as React from "react"
import { useDropzone } from "react-dropzone"

import { AxiosError } from "axios"

import createSoundFile from "@podcastsoundboard/ps-lib/api/soundFiles/create"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"

import { useAppDispatch } from "../../../../../../redux"
import { addSnackbar } from "../../../../../../redux/snackbars"

interface DropzoneProps {
  onFile: (file: File) => void
}

const Dropzone: React.FC<DropzoneProps> = ({ onFile }) => {
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      onFile(acceptedFiles[0])
    },
    [onFile],
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button variant="contained">Add Sound File</Button>
    </div>
  )
}

interface AddSoundFileProps {
  onCreate?: () => void
  color?: string
}

const AddSoundFile: React.FC<AddSoundFileProps> = ({ onCreate, color }) => {
  const dispatch = useAppDispatch()

  const [uploading, setUploading] = React.useState<boolean>(false)

  const uploadFile = (file: File): void => {
    if (!file) return console.error("File missing.")
    const formData = new FormData()
    formData.append("file", file, file.name)

    setUploading(true)
    createSoundFile(formData)
      .then(() => {
        setUploading(false)
        if (onCreate) onCreate()
      })
      .catch((err: AxiosError) => {
        setUploading(false)
        console.error("Error uploading sound files", err)
        const text =
          (err.response && err.response.data && err.response.data.error) ||
          "Failed to upload sound file."
        dispatch(addSnackbar({ id: "uploadSoundFilesFailed", text }))
      })
  }

  return (
    <Box display="flex" alignItems="center">
      {uploading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress style={{ color }} sx={{ m: 2 }} size={30} />
        </Box>
      )}
      {!uploading && <Dropzone onFile={uploadFile} />}
    </Box>
  )
}

export default AddSoundFile
