import AppleIcon from "@mui/icons-material/Apple"
import PublicIcon from "@mui/icons-material/Public"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

const ICON_FONT_SIZE = "3rem"
const WINDOW_ICON_FONT_SIZE = "2.3rem"
// const ANDROID_ICON_FONT_SIZE = "2.6rem"

const DeviceGridItem = ({ device }) => {
  const {
    icon,
    subtitle,
    description,
    lifetimeLicense,
    price,
    url,
    buttonColor,
    windowsIncluded,
    actionButtonLabel = "Learn more",
  } = device
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper
        elevation={4}
        style={{
          padding: 2,
          height: "100%",
        }}
      >
        <Stack
          height="100%"
          direction="column"
          alignItems="stretch"
          justifyContent="space-between"
          spacing={2}
          sx={{ p: 2 }}
        >
          <Stack direction="column" spacing={1} width="100%" flexGrow={1}>
            <Stack
              flexWrap="wrap"
              direction="row"
              // justifyContent="flex-end"
              alignItems="center"
              width="100%"
              gap={"0.5rem"}
            >
              {icon}
              <Box flexGrow={1}>
                <Typography
                  variant="body1"
                  paddingTop="0.2rem"
                  fontSize="1.2rem"
                  color="text.primary"
                  fontWeight={"bold"}
                >
                  {subtitle}
                </Typography>
              </Box>

              <Typography
                variant="body1"
                paddingTop="0.2rem"
                fontSize="1.5rem"
                color="text.primary"
              >
                {price}
              </Typography>

              {/* <Chip
                size="small"
                icon={<ShoppingCartIcon sx={{ p: 0.1 }} />}
                label={price}
                clickable={Boolean(buyNow || url)}
                onClick={() => window.open(buyNow || url, "_blank")}
                color="success"
                variant="outlined"
              /> */}
            </Stack>

            <Typography
              variant="body2"
              color="text.primary"
              style={{ marginTop: "0.5rem" }}
              flexGrow={1}
            >
              {description}
            </Typography>

            <Stack direction="row" alignItems="center" flexWrap="wrap">
              {windowsIncluded && (
                <Chip
                  label="Windows included!"
                  variant="outlined"
                  clickable
                  style={{ marginTop: "0.25rem" }}
                  size="small"
                  onClick={() => window.open("/app/windows", "_blank")}
                />
              )}
              {lifetimeLicense && (
                <Chip
                  label="Lifetime license!"
                  variant="outlined"
                  style={{ marginTop: "0.25rem" }}
                  size="small"
                />
              )}
            </Stack>
            {url && (
              <Button
                fullWidth
                onClick={() => window.open(url, "_blank")}
                color={buttonColor}
                // size="small"
                variant="contained"
              >
                {actionButtonLabel}
              </Button>
            )}
          </Stack>
        </Stack>
      </Paper>
    </Grid>
  )
}

export default function DeviceCards() {
  const devices = [
    {
      name: "Web",
      subtitle: "Web",
      description:
        "Create an account to try the web version for free. Upgrade to an affordable premium subscription for unlimited sounds, and bonus access to the Windows app.",
      url: "/app/account/soundboards/new",
      icon: <PublicIcon style={{ fontSize: ICON_FONT_SIZE }} color="primary" />,
      price: "From $2.95/mo",
      windowsIncluded: true,
      buttonColor: "primary",
      actionButtonLabel: "Make free account",
    },
    {
      name: "MacOS 10.15+",
      url: "https://apps.apple.com/us/app/podcast-soundboard/id1265723161",
      changelog: "/app/windows/changelog",
      description:
        "Podcast Soundboard is the leading soundboard application on the MacOS app store. Perfect for MIDI and offline support.",
      icon: <AppleIcon style={{ fontSize: ICON_FONT_SIZE }} />,
      price: "$14.99",
      subtitle: "MacOS",
      lifetimeLicense: true,
      buttonColor: "primary",
      actionButtonLabel: "View on MacOS app store",
    },
    {
      name: "Windows",
      changelog: "/app/windows/changelog",
      url: "/app/windows",
      subtitle: "Windows",
      price: "$14.95",
      description:
        "Purchase a lifetime license for Podcast Soundboard for Windows directly from our website. Alternatively, access to the Windows app is included with a web subscription.",
      icon: (
        <img
          style={{ height: WINDOW_ICON_FONT_SIZE }}
          alt="Windows"
          src={"/img/windows-blue.svg"}
        />
      ),
      lifetimeLicense: true,
      buttonColor: "primary",
      actionButtonLabel: "Download now",
    },
    // {
    //   name: "iOS 13+",
    //   subtitle: "iOS",
    //   changelog: "/app/platforms/mobile/changelog",
    //   url:
    //     "https://apps.apple.com/us/app/podcast-soundboard-mobile/id1615987937",
    //   icon: <AppleIcon style={{ fontSize: ICON_FONT_SIZE }} />,
    //   description:
    //     "Download Podcast Soundboard for iOS free today! Find us on the iOS app store. Portable, offline, and feature-rich",
    //   price: "Free!",
    //   lifetimeLicense: true,
    //   buttonColor: "primary",
    // },
    // {
    //   name: "Android",
    //   subtitle: "Android",
    //   changelog: "/app/platforms/mobile/changelog",
    //   url:
    //     "https://play.google.com/store/apps/details?id=com.alexanderpaterson.PodcastSoundboardMobile2",
    //   price: "Free!",
    //   description:
    //     "Podcast Soundboard is the most well-polished soundbite app on the Google Play store. Best of all, it's completely free. Download today!",
    //   buttonColor: "primary",
    //   lifetimeLicense: true,
    //   icon: (
    //     <AndroidIcon color="success" style={{ fontSize: ICON_FONT_SIZE }} />
    //   ),
    // },
  ]

  return (
    <Grid container spacing={4} justifyContent="center">
      {devices.map((device) => (
        <DeviceGridItem key={device.name} device={device} />
      ))}
    </Grid>
  )
}
