import {
  Box,
  Card,
  CardContent,
  ChipProps,
  Grid,
  Rating,
  Stack,
  Typography,
} from "@mui/material"

export default function ReviewCard({
  icon,
  title,
  subtitle,
  description,
}: {
  icon: ChipProps["icon"]
  title: string
  subtitle: string
  description: string
}) {
  return (
    <Grid item xs={12} sm={4}>
      <Card elevation={4} style={{ height: "100%" }}>
        <CardContent>
          <Stack direction="row" alignItems="flex-start" spacing={2}>
            {icon}
            <Box flexGrow={1}>
              {title && (
                <Typography variant="h5" component="div">
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {subtitle}
                </Typography>
              )}
            </Box>
          </Stack>
          <Rating readOnly value={5} size="large" />
          <Typography variant="subtitle2">{description}</Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}
