import * as React from "react"
import { useNavigate } from "react-router-dom"

import { format } from "date-fns"

import User from "@podcastsoundboard/ps-lib/types/User"

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import CheckIcon from "@mui/icons-material/Check"
import EditIcon from "@mui/icons-material/Edit"
import FilterListIcon from "@mui/icons-material/FilterList"
import LocalAtmIcon from "@mui/icons-material/LocalAtm"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import IconButton from "@mui/material/IconButton"
import Pagination from "@mui/material/Pagination"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import searchUsers from "../../../../api/users/search"
import Copyright from "../../../../components/Copyright"
import FilterContainer from "../../../../components/FilterContainer"
import HeadingContainer from "../../../../components/HeadingContainer"
import nameForUser from "../../../../helpers/strings/nameForUser"
import { darkTheme, lightTheme } from "../../../../lib/muiTheme"
import { useAppSelector } from "../../../../redux"

let queryTimeout: NodeJS.Timeout

const PER_PAGE = 10

export default function AdminUserManagement() {
  const navigate = useNavigate()
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const muiTheme = darkModeEnabled ? darkTheme : lightTheme

  const [usersLoading, setUsersLoading] = React.useState(true)
  const [users, setUsers] = React.useState<User[] | null>(null)
  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)
  const [errorMessage, setErrorMessage] = React.useState("")
  const [subscribed, setSubscribed] = React.useState(false)

  const [email, setEmail] = React.useState("")
  const [name, setName] = React.useState("")

  const handleSearchUsers = React.useCallback(() => {
    searchUsers({
      email,
      name,
      page,
      perPage: PER_PAGE,
      subscribed,
    })
      .then(({ users, totalPages }) => {
        if (totalPages < page) setPage(totalPages)
        setUsers(users)
        setTotalPages(totalPages)
        setUsersLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setUsersLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message,
        )
      })
  }, [email, name, page, subscribed])

  React.useEffect(() => {
    setUsersLoading(true)
    clearTimeout(queryTimeout)
    queryTimeout = setTimeout(() => {
      handleSearchUsers()
    }, 300)
  }, [name, email, page, handleSearchUsers])

  return (
    <div>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>
      )}

      <HeadingContainer>
        <Typography component="h1" variant="h6" noWrap color="textPrimary">
          Users
        </Typography>
      </HeadingContainer>

      <Divider />

      <FilterContainer>
        <FilterListIcon
          sx={{ color: muiTheme.palette.text.primary, fontSize: 22 }}
        />
        <Typography
          variant="body1"
          color="textPrimary"
          style={{
            fontSize: "13px",
            paddingLeft: "0.15rem",
            paddingRight: "1rem",
          }}
        >
          Filter
        </Typography>

        <TextField
          sx={{ pr: 2 }}
          size="small"
          value={email || ""}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          variant="outlined"
        />

        <TextField
          sx={{ pr: 2 }}
          size="small"
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
          label="Name"
          variant="outlined"
        />

        <FormControlLabel
          color="text.primary"
          control={
            <Checkbox
              checked={subscribed}
              onChange={(e) => setSubscribed(e.target.checked)}
            />
          }
          label="Subscribed"
        />
      </FilterContainer>

      <Divider sx={{ mb: 2 }} />

      {usersLoading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}

      {!usersLoading && (
        <Container maxWidth={false}>
          <Pagination
            sx={{ mb: 3 }}
            count={totalPages}
            page={page}
            onChange={(_, v) => setPage(v)}
          />

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <b>Created At</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Email</b>
                  </TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {users &&
                  users.map((user) => (
                    <TableRow
                      key={user.email}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">
                        {format(new Date(user.createdAt), "Pp")}
                      </TableCell>
                      <TableCell align="left">
                        <Box>
                          {user.email}{" "}
                          {nameForUser(user) && `(${nameForUser(user)})`}
                        </Box>
                        {user.whoAmI && <Box>{user.whoAmI}</Box>}
                        {user.tellUsAboutYourself && (
                          <Box>{user.tellUsAboutYourself}</Box>
                        )}
                        {user.numberOneFeatureRequest && (
                          <Box>{user.numberOneFeatureRequest}</Box>
                        )}
                      </TableCell>
                      <TableCell align="right" sx={{ pr: 2 }}>
                        {user.emailConfirmed && (
                          <Tooltip title="Email confirmed">
                            <IconButton>
                              <CheckIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {user.hasActiveStripeSubscription && (
                          <Tooltip
                            title={`Paid subscription (${
                              user.stripeSubscriptionCancelled
                                ? "ending"
                                : "renewing"
                            } on ${format(
                              new Date(user.stripeSubscriptionEndsAt),
                              "dd/MM/yyyy",
                            )}`}
                          >
                            <IconButton>
                              <LocalAtmIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {user.isAdmin && (
                          <Tooltip title="Admin">
                            <IconButton>
                              <AdminPanelSettingsIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() =>
                              navigate(`/app/admin/users/${user.uuid}`)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}

      <Copyright sx={{ pt: 4 }} />
    </div>
  )
}
