// purpose of this module is to have a window object on serverside.
// serverside can't globally define window because MUI5 looks at it to find out
// whether to use useLayoutEffect or useEffect, and useLayoutEffect generates warnings
// import { WindowParams } from '../../types/types'

let navigatorProxy: Navigator

if (typeof navigator === "undefined") {
  navigatorProxy = {} as unknown as Navigator
} else {
  navigatorProxy = navigator
}

export default navigatorProxy
