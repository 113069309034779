import * as React from "react"
import { useNavigate, useParams } from "react-router-dom"

import dayjs from "dayjs"

import User from "@podcastsoundboard/ps-lib/types/User"

import LoadingButton from "@mui/lab/LoadingButton"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Checkbox from "@mui/material/Checkbox"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Divider from "@mui/material/Divider"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

import Paper from "@mui/material/Paper"
import deleteUser from "../../../../api/users/delete"
import fetchUser from "../../../../api/users/fetch"
import updateUser from "../../../../api/users/update"
import Copyright from "../../../../components/Copyright"
import HeadingContainer from "../../../../components/HeadingContainer"
import { useAppDispatch, useAppSelector } from "../../../../redux"
import { setCurrentUserProfile } from "../../../../redux/currentUserProfile"
import { addSnackbar } from "../../../../redux/snackbars"

export default function AdminEditUser() {
  const { userUuid } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const [userLoading, setUserLoading] = React.useState(true)
  const [user, setUser] = React.useState<User | null>(null)
  const [errorMessage, setErrorMessage] = React.useState("")
  const [stripeSubscriptionEndsAt, setStripeSubscriptionEndsAt] =
    React.useState(dayjs("2022-00-00T00:00"))

  const [isAdmin, setIsAdmin] = React.useState(false)
  const [firstname, setFirstname] = React.useState("")
  const [lastname, setLastname] = React.useState("")

  const [isDirty, setDirty] = React.useState(false)
  const [isSaving, setSaving] = React.useState(false)

  const [areYouSureDeleteOpen, setAreYouSureDeleteOpen] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)

  const handleReceiveUserProfile = (user: User) => {
    setUser(user)
    setFirstname(user.firstname || "")
    setLastname(user.lastname || "")
    setIsAdmin(user.isAdmin || false)
    setStripeSubscriptionEndsAt(dayjs(user.stripeSubscriptionEndsAt))
  }

  React.useEffect(() => {
    if (!userUuid) return
    fetchUser(userUuid)
      .then((user) => {
        setUserLoading(false)
        handleReceiveUserProfile(user)
      })
      .catch((err) => {
        console.error(err)
        setUserLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message,
        )
      })
  }, [userUuid])

  const deleteUserCallback = React.useCallback(() => {
    setIsDeleting(true)

    if (!userUuid) return
    return deleteUser(userUuid)
      .then(() => {
        setIsDeleting(false)
        navigate("/app/admin/users")
        dispatch(addSnackbar({ text: "User deleted." }))
      })
      .catch((err) => {
        setIsDeleting(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message ||
            "Failed to delete user.",
        )
      })
  }, [dispatch, navigate, userUuid])

  const handleSave = () => {
    setSaving(true)
    if (!userUuid) return
    updateUser(userUuid, {
      isAdmin,
      firstname,
      lastname,
      stripeSubscriptionEndsAt: stripeSubscriptionEndsAt?.toJSON(),
    })
      .then((user) => {
        handleReceiveUserProfile(user)
        setSaving(false)
        dispatch(addSnackbar({ text: "User updated." }))
        if (!currentUserProfile) return
        if (user.uuid === currentUserProfile.uuid)
          dispatch(setCurrentUserProfile(user))
      })
      .catch((err) => {
        console.error(err)
        setSaving(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message,
        )
      })
  }

  return (
    <div>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>
      )}

      <Dialog
        open={areYouSureDeleteOpen}
        onClose={() => setAreYouSureDeleteOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">Delete user?</DialogTitle>

        <DialogContent>
          <Typography variant="body1">This action cannot be undone.</Typography>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            onClick={() => deleteUserCallback()}
            loading={isDeleting}
            color="warning"
            variant="contained"
          >
            Delete
          </LoadingButton>
          <LoadingButton onClick={() => setAreYouSureDeleteOpen(false)}>
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <HeadingContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            onClick={(e) => {
              e.preventDefault()
              navigate("/app/admin/users")
            }}
            underline="hover"
            color="inherit"
            href="/app/admin/users"
          >
            Users
          </Link>

          <Typography color="text.primary">
            {user && user.email}
            {userLoading && (
              <Skeleton animation="wave" style={{ minWidth: "200px" }} />
            )}
            {!userLoading && !user && "Unknown"}
          </Typography>
        </Breadcrumbs>
      </HeadingContainer>

      <Divider sx={{ mb: 2 }} />

      {userLoading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}

      {!userLoading && (
        <Container maxWidth="sm">
          <Paper sx={{ mt: 2, p: 4 }}>
            <FormGroup>
              <Box component="form" noValidate>
                <Grid item sx={{ mt: 2 }} xs={12}>
                  <TextField
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    value={firstname || ""}
                    label="First Name"
                    onChange={(e) => {
                      setDirty(true)
                      setFirstname(e.target.value)
                    }}
                  />
                </Grid>

                <Grid item sx={{ mt: 2 }} xs={12}>
                  <TextField
                    name="lastName"
                    required
                    fullWidth
                    id="lastName"
                    value={lastname || ""}
                    label="Last Name"
                    onChange={(e) => {
                      setDirty(true)
                      setLastname(e.target.value)
                    }}
                  />
                </Grid>

                <Grid item sx={{ mt: 2 }} xs={12}>
                  <FormControlLabel
                    label="Is Admin?"
                    sx={{ color: "text.primary" }}
                    control={
                      <Checkbox
                        checked={isAdmin}
                        onChange={(e) => {
                          setDirty(true)
                          setIsAdmin(e.target.checked)
                        }}
                      />
                    }
                  />
                </Grid>

                <Grid item sx={{ mt: 2 }} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimeField
                      label="Subscription ends at"
                      fullWidth
                      value={stripeSubscriptionEndsAt}
                      onChange={(newValue) => {
                        setDirty(true)
                        if (newValue) setStripeSubscriptionEndsAt(newValue)
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item sx={{ mt: 2 }} xs={12}>
                  <LoadingButton
                    fullWidth
                    onClick={() => setAreYouSureDeleteOpen(true)}
                    loading={isDeleting}
                    variant="contained"
                    color="warning"
                  >
                    Delete User
                  </LoadingButton>
                </Grid>

                <Grid item sx={{ mt: 2 }} xs={12}>
                  <LoadingButton
                    fullWidth
                    onClick={handleSave}
                    loading={isSaving}
                    disabled={!isDirty || userLoading || !user}
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Box>
            </FormGroup>
          </Paper>
        </Container>
      )}

      <Copyright sx={{ pt: 4 }} />
    </div>
  )
}
