import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

import Grid from "@mui/material/Grid"

import audioStack from "../../../../lib/audioStack"
import { Soundboard } from "../../../../types/Soundboard"
import Pad from "../../../Soundboards/components/Pad"

const soundboard: Soundboard = {
  id: 1,

  uuid: "string",

  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),

  userUuid: "x",

  name: "Soundboard",

  volume: 1,
  duckPercentage: 0.1,
  solo: false,

  pads: [
    {
      id: 1,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      multishot: false,
      alwaysPlayFromStart: false,
      autoFadeInDuration: 0,
      fadeInOnPressDuration: 0,
      fadeOutOnPressDuration: 0,
      userUuid: "c325ab5a-979c-40bb-b6ff-778fe9e3e7b-3",
      uuid: "c325ab5a-979c-40bb-b6ff-778fe9e3e7b6-1",
      soundboardUuid: "322544a6-cedc-4a38-b197-959acb3d02c8",
      soundFileUuid: "8579ee81-b561-4c23-88b2-49d937dce066",
      name: "Rimshot",
      shortcut: "1",
      color: "#9c27b0",
      loop: false,
      midiBinding: "",
      startTime: 0,
      endTime: 0,
      volume: 100,
      fadeOutDuration: 0,
      sortIndex: 0,
      soundfile: {
        id: 1,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        fileSizeBytes: 5,
        freesoundId: "x",
        voicyClipId: "x",
        uuid: "8579ee81-b561-4c23-88b2-49d937dce066",
        filepath: "public/Rimshot Clean.wav",
        filename: "Rimshot Clean.mp3",
        name: "Rimshot Clean.mp3",
        userUuid: "789f4e51-7838-4bf3-bc53-797fa4bdc613",
        fileUrl:
          "https://podcast-soundboard-cdn.s3.amazonaws.com/home-sounds/Rimshot+Clean.wav",
      },
    },
    {
      id: 1,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      multishot: false,
      alwaysPlayFromStart: false,
      autoFadeInDuration: 0,
      fadeInOnPressDuration: 0,
      fadeOutOnPressDuration: 0,
      userUuid: "c325ab5a-979c-40bb-b6ff-778fe9e3e7b-3",
      uuid: "c325ab5a-979c-40bb-b6ff-778fe9e3e7b-2",
      soundboardUuid: "322544a6-cedc-4a38-b197-959acb3d02c8",
      soundFileUuid: "8579ee81-b561-4c23-88b2-49d937dce066",
      name: "Oh Yeah",
      shortcut: "2",
      color: "#e91e63",
      loop: false,
      midiBinding: "",
      startTime: 0,
      endTime: 0,
      volume: 100,
      fadeOutDuration: 0,
      sortIndex: 0,
      soundfile: {
        id: 1,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        fileSizeBytes: 5,
        freesoundId: "x",
        voicyClipId: "x",
        uuid: "8579ee81-b561-4c23-88b2-49d937dce066",
        filepath: "public/Oh Yeah (Deep).wav",
        filename: "Oh Yeah (Deep).wav",
        name: "Oh Yeah (Deep).wav",
        userUuid: "789f4e51-7838-4bf3-bc53-797fa4bdc613",
        fileUrl:
          "https://podcast-soundboard-cdn.s3.amazonaws.com/home-sounds/Oh+Yeah+(Deep).wav",
      },
    },
    {
      id: 1,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      multishot: false,
      alwaysPlayFromStart: false,
      autoFadeInDuration: 0,
      fadeInOnPressDuration: 0,
      fadeOutOnPressDuration: 0,
      userUuid: "c325ab5a-979c-40bb-b6ff-778fe9e3e7b-3",
      uuid: "c325ab5a-979c-40bb-b6ff-778fe9e3e7b-3",
      soundboardUuid: "322544a6-cedc-4a38-b197-959acb3d02c8",
      soundFileUuid: "8579ee81-b561-4c23-88b2-49d937dce066",
      name: "Flush",
      midiBinding: "C3",
      color: "#673ab7",
      shortcut: "",
      loop: false,
      startTime: 0,
      endTime: 0,
      volume: 100,
      fadeOutDuration: 0,
      sortIndex: 0,
      soundfile: {
        id: 1,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        fileSizeBytes: 5,
        freesoundId: "x",
        voicyClipId: "x",
        uuid: "8579ee81-b561-4c23-88b2-49d937dce066",
        filepath: "public/Flush 2.mp3",
        filename: "Flush 2.mp3",
        name: "Flush (Long).mp3",
        userUuid: "789f4e51-7838-4bf3-bc53-797fa4bdc613",
        fileUrl:
          "https://podcast-soundboard-cdn.s3.amazonaws.com/home-sounds/Flush+2.mp3",
      },
    },
    {
      id: 1,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      multishot: false,
      alwaysPlayFromStart: false,
      autoFadeInDuration: 0,
      fadeInOnPressDuration: 0,
      fadeOutOnPressDuration: 0,
      userUuid: "c325ab5a-979c-40bb-b6ff-778fe9e3e7b-3",
      uuid: "c325ab5a-979c-40bb-b6ff-778fe9e3e7b-4",
      soundboardUuid: "322544a6-cedc-4a38-b197-959acb3d02c8",
      soundFileUuid: "8579ee81-b561-4c23-88b2-49d937dce066",
      name: "Barking Dog",
      shortcut: "",
      color: "#f44336",
      loop: false,
      midiBinding: "",
      startTime: 0,
      endTime: 0,
      volume: 100,
      fadeOutDuration: 0,
      sortIndex: 0,
      soundfile: {
        id: 1,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        fileSizeBytes: 5,
        freesoundId: "x",
        voicyClipId: "x",
        uuid: "8579ee81-b561-4c23-88b2-49d937dce066",
        filepath: "public/Barking Dog.mp3",
        filename: "Barking Dog.mp3",
        name: "Barking Dog.mp3",
        userUuid: "789f4e51-7838-4bf3-bc53-797fa4bdc613",
        fileUrl:
          "https://podcast-soundboard-cdn.s3.amazonaws.com/home-sounds/Barking+Dog.mp3",
      },
    },
  ],
}

export default function SoundboardPreview() {
  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container spacing={0}>
        {soundboard.pads?.map((pad) => (
          <Grid key={pad.uuid} item xs={6} sx={{ p: 1 }}>
            <Pad
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              overrideOnCogPress={() => {}}
              audioInterface={audioStack.interfaceForSoundboardPad(
                soundboard.uuid,
                pad.uuid,
              )}
              pad={pad}
              overrideSoundboard={soundboard}
              solo={false}
              keyIndex={0}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              setDragDropSaving={() => {}}
            />
          </Grid>
        ))}
      </Grid>
    </DndProvider>
  )
}
