import * as React from "react"
import { useNavigate } from "react-router-dom"

import searchSoundboards from "@podcastsoundboard/ps-lib/api/soundboards/search"
import SoundboardCard from "@podcastsoundboard/ps-lib/components/SoundboardCard"

import FilterListIcon from "@mui/icons-material/FilterList"
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import Copyright from "../../../../components/Copyright"
import EditSoundboardModal from "../../../../components/EditSoundboardModal"
import FilterContainer from "../../../../components/FilterContainer"
import HeadingContainer from "../../../../components/HeadingContainer"
import { darkTheme, lightTheme } from "../../../../lib/muiTheme"
import { useAppSelector } from "../../../../redux"

import { Soundboard } from "../../../../types/Soundboard"

let queryTimeout: NodeJS.Timeout

const PER_PAGE = 12

export default function AdminSoundboardManagement() {
  const navigate = useNavigate()
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)

  const muiTheme = darkModeEnabled ? darkTheme : lightTheme

  const [soundboardsLoading, setSoundboardsLoading] = React.useState(true)
  const [soundboards, setSoundboards] = React.useState<Soundboard[] | null>(
    null,
  )
  const [email, setEmail] = React.useState("")
  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)
  const [errorMessage, setErrorMessage] = React.useState("")
  const [publicFilter, setPublicFilter] = React.useState("all")

  const [editSoundboardModalShown, setEditSoundboardModalShown] =
    React.useState(false)
  const [soundboardToEdit, setSoundboardToEdit] =
    React.useState<Soundboard | null>(null)

  const handleSearchSoundboards = React.useCallback(() => {
    searchSoundboards({
      email,
      page,
      perPage: PER_PAGE,

      isPublic: (publicFilter === "public" && true) || undefined,
      requestFeatured:
        (publicFilter === "requestFeatured" && true) || undefined,
      featured: (publicFilter === "featured" && true) || undefined,
    })
      .then(({ soundboards, totalPages }) => {
        if (totalPages < page) setPage(totalPages)
        setSoundboards(soundboards)
        setTotalPages(totalPages)
        setSoundboardsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setSoundboardsLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message,
        )
      })
  }, [email, page, publicFilter])

  React.useEffect(() => {
    setSoundboardsLoading(true)
    clearTimeout(queryTimeout)
    queryTimeout = setTimeout(() => {
      handleSearchSoundboards()
    }, 300)
  }, [page, publicFilter, handleSearchSoundboards])

  return (
    <>
      {soundboardToEdit && (
        <EditSoundboardModal
          open={editSoundboardModalShown}
          soundboard={soundboardToEdit}
          handleClose={() => {
            setEditSoundboardModalShown(false)
            setSoundboardToEdit(null)
          }}
          onUpdate={() => {
            setEditSoundboardModalShown(false)
            setSoundboardToEdit(null)
            handleSearchSoundboards()
          }}
        />
      )}

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>
      )}

      <HeadingContainer>
        <Typography component="h1" variant="h6" noWrap color="textPrimary">
          Soundboards
        </Typography>
      </HeadingContainer>

      <FilterContainer>
        <FilterListIcon
          sx={{ color: muiTheme.palette.text.primary, fontSize: 22 }}
        />
        <Typography
          variant="body1"
          color="textPrimary"
          style={{
            fontSize: "13px",
            paddingLeft: "0.15rem",
            paddingRight: "1rem",
          }}
        >
          Filter
        </Typography>

        <TextField
          style={{ paddingRight: "1rem" }}
          size="small"
          value={email || ""}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          variant="outlined"
        />

        <FormControl style={{ width: "140px" }}>
          <InputLabel size="small" id="public-filter">
            Public
          </InputLabel>
          <Select
            fullWidth
            size="small"
            labelId="public-filter"
            label="Public"
            value={publicFilter}
            onChange={(e) => setPublicFilter(e.target.value)}
          >
            <MenuItem value="all">
              <Typography variant="body1">All</Typography>
            </MenuItem>
            <MenuItem value="public">
              <Typography variant="body1">Public</Typography>
            </MenuItem>
            <MenuItem value="requestFeatured">
              <Typography variant="body1">Submitted</Typography>
            </MenuItem>
            <MenuItem value="featured">
              <Typography variant="body1">Published</Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </FilterContainer>

      <Divider sx={{ mb: 2 }} />

      {soundboardsLoading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}

      {!soundboardsLoading && (
        <Container maxWidth="xl">
          <Pagination
            sx={{ mb: 3 }}
            count={totalPages}
            page={page}
            onChange={(_, v) => setPage(v)}
          />
          <Grid container spacing={2}>
            {soundboards &&
              soundboards.map((soundboard) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={soundboard.uuid}>
                  <SoundboardCard
                    asAdmin
                    frontendWebSoundboard={soundboard}
                    onSetSoundboardToEdit={() => {
                      setSoundboardToEdit(soundboard)
                      setEditSoundboardModalShown(true)
                    }}
                    onOpenSoundboard={() => {
                      navigate(`/app/admin/soundboards/${soundboard.uuid}`)
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        </Container>
      )}

      <Copyright sx={{ pt: 4 }} />
    </>
  )
}
