import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import SettingsIcon from '@mui/icons-material/Settings'
import UndoIcon from '@mui/icons-material/Undo'
import MuiButton, { ButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import { PRIMARY_PURPLE } from '../../colors'
import tinycolor from 'tinycolor2'
import Box from '@mui/material/Box'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const ICON_BUTTON_SIZE = 24
const BIG_ICON_BUTTON_SX = { p: 0.1, minWidth: 0 }

const DEFAULT_COLOR = PRIMARY_PURPLE

const Button = (props: ButtonProps) => {
  return (
    <MuiButton
      {...props}
      style={{
        ...(props.style || {}),
        borderRadius: 0,
        height: '100%',
        flexGrow: 1,
        alignSelf: 'stretch',
      }}
      size="small"
    >
      {props.children}
    </MuiButton>
  )
}

function themesFromPrimary(primary: string) {
  return {
    darkTheme: createTheme({
      palette: {
        mode: 'dark',
        primary: {
          main: primary || PRIMARY_PURPLE,
        },
        secondary: {
          main: primary || PRIMARY_PURPLE,
        },
      },
    }),
    lightTheme: createTheme({
      palette: {
        mode: 'light',
        primary: {
          main: primary || PRIMARY_PURPLE,
        },
        secondary: {
          main: primary || PRIMARY_PURPLE,
        },
      },
    }),
  }
}

function PadInteractiveButtonsRow({
  skipback,
  play,
  pause,
  onSettingsPress,
  readyToPlay,
  playing,
  loading,
  fadeOutLastPressedAt,

  name,
  hasSoundFile,
  color = DEFAULT_COLOR,
  multishot,
  fadeOutOnPressDuration = 0,
  fadeInOnPressDuration = 0,
}: {
  skipback: () => void
  play: () => void
  pause: () => void
  onSettingsPress: () => void
  readyToPlay: boolean
  playing: boolean
  loading: boolean
  fadeOutLastPressedAt: number | null | undefined

  name: string
  hasSoundFile: boolean
  color: string
  multishot: boolean
  fadeOutOnPressDuration: number
  fadeInOnPressDuration: number
}) {
  const textColor = tinycolor
    .mostReadable(color, ['#333', '#fff'])
    .toHexString()

  const { darkTheme } = themesFromPrimary(color)
  return (
    <ThemeProvider theme={darkTheme}>
      <Grid
        container
        columns={3}
        sx={{
          backgroundColor: color,
          '& .MuiButtonBase-root': {
            backgroundColor: color,
          },
        }}
      >
        {!loading && readyToPlay && (
          <>
            <Grid item xs={1}>
              <Tooltip title="Back to start">
                <Box style={{ height: '100%' }}>
                  <Button
                    style={{ height: '100%' }}
                    variant="contained"
                    fullWidth
                    aria-label="back"
                    onClick={skipback}
                    sx={BIG_ICON_BUTTON_SX}
                  >
                    <UndoIcon
                      style={{
                        color: textColor,
                        height: ICON_BUTTON_SIZE,
                        width: ICON_BUTTON_SIZE,
                      }}
                    />
                  </Button>
                </Box>
              </Tooltip>
            </Grid>

            {!playing && !fadeInOnPressDuration && (
              <Grid item xs={1}>
                <Tooltip title="Play">
                  <Box style={{ height: '100%' }}>
                    <Button
                      style={{ height: '100%' }}
                      variant="contained"
                      fullWidth
                      aria-label="back"
                      onClick={play}
                      sx={BIG_ICON_BUTTON_SX}
                    >
                      <PlayArrowIcon
                        style={{
                          color: textColor,
                          height: ICON_BUTTON_SIZE,
                          width: ICON_BUTTON_SIZE,
                        }}
                      />
                    </Button>
                  </Box>
                </Tooltip>
              </Grid>
            )}
            {!playing && Boolean(fadeInOnPressDuration) && (
              <Grid item xs={1}>
                <Tooltip title={`Fade in (${fadeInOnPressDuration})`}>
                  <div style={{ height: '100%' }}>
                    <Button
                      style={{ height: '100%' }}
                      variant="contained"
                      fullWidth
                      onClick={play}
                      disabled={!hasSoundFile}
                      sx={BIG_ICON_BUTTON_SX}
                    >
                      <ArrowUpwardIcon
                        style={{
                          color: textColor,
                          height: ICON_BUTTON_SIZE,
                          width: ICON_BUTTON_SIZE,
                        }}
                      />
                    </Button>
                  </div>
                </Tooltip>
              </Grid>
            )}

            {/* no pause for multi-shot, use skipback */}
            {playing && !multishot && !fadeOutOnPressDuration && (
              <Grid item xs={1}>
                <Tooltip title="Pause">
                  <div style={{ height: '100%' }}>
                    <Button
                      style={{ height: '100%' }}
                      variant="contained"
                      fullWidth
                      onClick={pause}
                      disabled={!hasSoundFile}
                      sx={BIG_ICON_BUTTON_SX}
                    >
                      <PauseIcon
                        style={{
                          color: textColor,
                          height: ICON_BUTTON_SIZE,
                          width: ICON_BUTTON_SIZE,
                        }}
                      />
                    </Button>
                  </div>
                </Tooltip>
              </Grid>
            )}
            {playing && !multishot && Boolean(fadeOutOnPressDuration) && (
              <Grid item xs={1}>
                <Tooltip title={`Fade out (${fadeOutOnPressDuration}s)`}>
                  <div style={{ height: '100%' }}>
                    <Button
                      style={{ height: '100%' }}
                      variant="contained"
                      fullWidth
                      onClick={pause}
                      disabled={Boolean(fadeOutLastPressedAt) || !hasSoundFile}
                      sx={BIG_ICON_BUTTON_SX}
                    >
                      <ArrowDownwardIcon
                        style={{
                          color: textColor,
                          height: ICON_BUTTON_SIZE,
                          width: ICON_BUTTON_SIZE,
                        }}
                      />
                    </Button>
                  </div>
                </Tooltip>
              </Grid>
            )}

            {playing && multishot && (
              <Grid item xs={1}>
                <Tooltip title="Multi-fire">
                  <div style={{ height: '100%' }}>
                    <Button
                      style={{ height: '100%' }}
                      variant="contained"
                      fullWidth
                      onClick={play}
                      disabled={!name}
                      sx={BIG_ICON_BUTTON_SX}
                    >
                      <ContentCopyIcon
                        style={{
                          color: textColor,
                          height: ICON_BUTTON_SIZE,
                          width: ICON_BUTTON_SIZE,
                        }}
                      />
                    </Button>
                  </div>
                </Tooltip>
              </Grid>
            )}
          </>
        )}

        {loading && (
          <Grid item xs={1}>
            <Button
              aria-label="Loading sound"
              disabled
              fullWidth
              variant="contained"
              style={{ height: '100%', backgroundColor: color }}
              sx={BIG_ICON_BUTTON_SX}
            >
              <CircularProgress size={20} style={{ color: textColor }} />
            </Button>
          </Grid>
        )}

        <Grid item xs={readyToPlay || loading ? 1 : 3}>
          <Tooltip title="Open soundpad settings">
            <Box style={{ height: '100%' }}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => onSettingsPress()}
                sx={BIG_ICON_BUTTON_SX}
              >
                <SettingsIcon
                  style={{
                    color: textColor,
                    height: ICON_BUTTON_SIZE,
                    width: ICON_BUTTON_SIZE,
                  }}
                />
              </Button>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default PadInteractiveButtonsRow
