import * as React from "react"

import SoundIcon from "@mui/icons-material/Audiotrack"
import CloudQueueIcon from "@mui/icons-material/CloudQueue"
import CloudSyncIcon from "@mui/icons-material/CloudSync" // Example import, replace with actual imports
import ColorLensIcon from "@mui/icons-material/ColorLens"
import ContentCutIcon from "@mui/icons-material/ContentCut"
import KeyboardIcon from "@mui/icons-material/Keyboard"
import ShortcutIcon from "@mui/icons-material/KeyboardCommandKey"
import ImageIcon from "@mui/icons-material/Image"
import LoopIcon from "@mui/icons-material/Loop"
import MidiIcon from "@mui/icons-material/MusicNote"
import OutputIcon from "@mui/icons-material/Output"
import PlayCircleIcon from "@mui/icons-material/PlayCircle"
// import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
// import PublicOffIcon from '@mui/icons-material/PublicOff';
import ComputerIcon from "@mui/icons-material/Computer"
import MobileOffIcon from "@mui/icons-material/MobileOff"
import PublicIcon from "@mui/icons-material/Public"
import TuneIcon from "@mui/icons-material/Tune"
import VolumeDownIcon from "@mui/icons-material/VolumeDown"
import VolumeUpIcon from "@mui/icons-material/VolumeUp"

// Placeholder icon, replace with specific icons as needed
import {
  Chip,
  Grid,
  Paper,
  Stack,
  SvgIconTypeMap,
  Typography,
} from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"

type MUIIcon = OverridableComponent<SvgIconTypeMap<unknown, "svg">> & {
  muiName: string
}

// Defining the feature type
interface Feature {
  name: string
  description: string // Assuming there's a description field you want to add
  webOnly?: string
  webOnlyIcon?: MUIIcon
  Icon: MUIIcon
}

// Example features array, add descriptions as needed
const features: Feature[] = [
  {
    name: "Sound Galleries",
    description:
      "Dive into an endless sea of sounds with Freesound and Voicy. Discover, play, and integrate unique sounds effortlessly.",

    Icon: CloudSyncIcon,
  },
  {
    name: "Web Soundboards",
    description:
      "Seamless access across all versions. Store your soundboards online and download them to any device.",
    Icon: CloudQueueIcon,
  },
  {
    name: "Trim Tracks",
    description:
      "Precision editing made easy. Trim your audio tracks to ensure your projects sound exactly the way you want.",
    Icon: ContentCutIcon,
  },
  {
    name: "Color Controls",
    description:
      "Add a splash of color to your projects. Our color controls let you set the mood and style of your soundboards, making them uniquely yours.",

    Icon: ColorLensIcon,
  },
  {
    name: "Advanced Fade Controls",
    description:
      "Craft the perfect entrance and exit for your audio with advanced fade controls. Elevate your sound design with smooth transitions.",
    Icon: TuneIcon,
  },
  {
    name: "Image Upload",
    description:
      "Find your sounds at a glance by uploading a custom image to represent your track.",
    Icon: ImageIcon,
  },
  {
    name: "Volume Control",
    description:
      "Master your audio levels with precise volume control at a sound, soundboard, or global level.",
    Icon: VolumeUpIcon,
  },
  {
    name: "Loop & Multi-fire",
    description:
      "Choose to loop tracks like background music, or enable Multi-Fire to layer copies of the same sound effect.",
    Icon: LoopIcon,
  },
  {
    name: "Duck Volume",
    description:
      "Seamlessly lower the volume of all tracks with the click of a button. Perfect for podcasts and narrations, ensuring your voice is always heard clearly.",
    Icon: VolumeDownIcon,
  },
  {
    name: "AI-generated Soundboards",
    description:
      "Effortlessly curate a set of sounds using AI-generated soundboards. Create fresh soundscapes in seconds, perfect for enhancing your projects or just having fun.",
    webOnly: "Web only",
    webOnlyIcon: PublicIcon,
    Icon: SoundIcon,
  },
  {
    name: "Keyboard Shortcuts",
    description:
      "Your sounds are at your fingertips with customizable keyboard shortcuts.",
    webOnly: "Not available on mobile",
    webOnlyIcon: MobileOffIcon,
    Icon: KeyboardIcon,
  },
  {
    name: "MIDI Support",
    description:
      "Connect your MIDI devices to play sounds with your favorite hardware seamlessly integrated into our platform.",
    webOnly: "Not available on mobile",
    webOnlyIcon: MobileOffIcon,
    Icon: MidiIcon,
  },
  {
    name: "Background Playback",
    description:
      "Keep the music going, even when you switch apps. With background playback, your soundtrack never misses a beat.",
    webOnly: "Not available on mobile",
    webOnlyIcon: MobileOffIcon,
    Icon: PlayCircleIcon,
  },
  {
    name: "Global Shortcuts",
    description:
      "Trigger your audio without breaking your workflow. Global shortcuts let you play and pause tracks without ever having to switch windows.",
    webOnly: "Desktop only",
    webOnlyIcon: ComputerIcon,
    Icon: ShortcutIcon,
  },
  {
    name: "Output Device Picker",
    description:
      "Route app audio to a specific playback device of your choosing.",
    webOnly: "Desktop only",
    webOnlyIcon: ComputerIcon,
    Icon: OutputIcon,
  },
]

const FeatureGrid: React.FC = () => {
  // Render a chip for platform availability
  // const renderChip = (available: boolean | "N/A", platform: string) => {
  //   if (available === "N/A")
  //     return <Chip label={`${platform} N/A`} variant="outlined" />
  //   return available ? (
  //     <Chip label={platform} color="success" />
  //   ) : (
  //     <Chip label={platform} color="error" />
  //   )
  // }
  const renderOnlyChip = (text: string, WebOnlyIcon?: MUIIcon) => {
    let color: "primary" | "info" | "warning" = "primary"
    if (text === "Web only") color = "info"
    if (text === "Desktop only") color = "warning"
    if (text === "Not available on mobile") color = "warning"
    return (
      <Chip
        label={`${text}`}
        size="small"
        variant="outlined"
        color={color}
        icon={WebOnlyIcon && <WebOnlyIcon />}
      />
    )
  }

  return (
    <Grid container spacing={4}>
      {features.map((feature, index) => (
        <Grid item xs={12} sm={6} md={4} key={index} alignItems={"stretch"}>
          <Paper
            elevation={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              p: 3,
              height: "100%",
              gap: "16px",
            }}
          >
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <feature.Icon sx={{ fontSize: 30 }} />
              <Typography variant="h6" gutterBottom>
                {feature.name}
              </Typography>
            </Stack>
            <Typography variant="body1" flexGrow="1" color="text.primary">
              {feature.description}
            </Typography>
            {feature.webOnly && (
              <Stack
                direction="row"
                spacing={1}
                justifyContent={"flex-end"}
                flexWrap="wrap"
                alignSelf="stretch"
              >
                {/* {renderChip(feature.web, "Web")}
                {renderChip(feature.desktop, "Desktop")}
                {renderChip(feature.ios, "iOS")} */}
                {feature.webOnly &&
                  renderOnlyChip(feature.webOnly, feature.webOnlyIcon)}
              </Stack>
            )}
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

export default FeatureGrid
