import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import { Grid } from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import searchSoundboards from "@podcastsoundboard/ps-lib/api/soundboards/search"
import SoundboardCard from "@podcastsoundboard/ps-lib/components/SoundboardCard"
import copySoundboard from "@podcastsoundboard/ps-lib/api/soundboards/copy"
import { SoundboardCardProps } from "@podcastsoundboard/ps-lib/components/SoundboardCard/SoundboardCard"
import * as React from "react"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useNavigate } from "react-router-dom"
import updateSoundboardOrder from "../../../../api/soundboards/updateOrder"
import EditSoundboardModal from "../../../../components/EditSoundboardModal"
import HeadingContainer from "../../../../components/HeadingContainer"
import NewSoundboardModal from "../../../../components/NewSoundboardModal"
import { useAppDispatch } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"
import { Soundboard } from "../../../../types/Soundboard"

const DraggableContainer = styled(Box)(() => ({
  position: "relative",
}))

const DragIcon = styled(DragIndicatorIcon)(({ theme }) => ({
  position: "absolute",
  top: 8,
  right: 8,
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.paper,
  borderRadius: "50%",
  padding: 4,
  zIndex: 1,
  cursor: "grab",
}))

const DraggableSoundboardCard = ({
  index,
  moveSoundboard,
  ...soundboardCardProps
}: {
  index: number
  moveSoundboard: (dragIndex: number, hoverIndex: number) => void
  frontendWebSoundboard: Soundboard
} & SoundboardCardProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [duplicating, setDuplicating] = React.useState(false)
  const { frontendWebSoundboard } = soundboardCardProps
  const ref = React.useRef(null)
  const dragIconRef = React.useRef<HTMLDivElement>(null)

  const [, drop] = useDrop({
    accept: "SOUNDBOARD",
    hover(item: { index: number }) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      moveSoundboard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: "SOUNDBOARD",
    item: () => ({ id: frontendWebSoundboard.uuid, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const handleDuplicate = React.useCallback(
    async (soundboardUuid: string) => {
      if (!soundboardUuid) return
      try {
        setDuplicating(true)
        const newSoundboard = await copySoundboard({
          soundboardUuid,
        })
        setDuplicating(false)
        dispatch(addSnackbar({ text: "New soundboard created" }))
        navigate(`/app/account/soundboards/${newSoundboard.uuid}`)
      } catch (err) {
        setDuplicating(false)
        console.error("Error duplicating soundboard", err)
        dispatch(addSnackbar({ text: "Error duplicating soundboard" }))
      }
    },
    [dispatch, navigate],
  )

  drag(dragIconRef)
  drop(ref)

  return (
    <DraggableContainer
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1, height: "100%" }}
    >
      <div className="dragIcon" ref={dragIconRef}>
        <DragIcon className="dragIcon" />
      </div>

      <SoundboardCard
        {...soundboardCardProps}
        duplicating={duplicating}
        onDuplicate={handleDuplicate}
      />
    </DraggableContainer>
  )
}

export default function SoundboardManagement() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [editSoundboardModalShown, setEditSoundboardModalShown] =
    React.useState(false)
  const [soundboardToEdit, setSoundboardToEdit] =
    React.useState<Soundboard | null>(null)
  const [soundboardsLoading, setSoundboardsLoading] = React.useState(true)
  const [soundboards, setSoundboards] = React.useState<Soundboard[] | null>(
    null,
  )
  const [newSoundboardModalShown, setNewSoundboardModalShown] =
    React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState("")

  const hasOrderChangedRef = React.useRef(false)
  const updateTimeoutRef = React.useRef<NodeJS.Timeout | null>(null)

  const handleSearchSoundboards = React.useCallback(() => {
    setSoundboardsLoading(true)
    searchSoundboards({ mine: true })
      .then(({ soundboards }) => {
        setSoundboards(soundboards)
        setSoundboardsLoading(false)
        hasOrderChangedRef.current = false
      })
      .catch((err) => {
        console.error(err)
        setSoundboardsLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message,
        )
      })
  }, [])

  React.useEffect(() => {
    handleSearchSoundboards()
  }, [handleSearchSoundboards])

  const moveSoundboard = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setSoundboards((prevSoundboards) => {
        if (!prevSoundboards) return null
        const newSoundboards = [...prevSoundboards]
        const [reorderedItem] = newSoundboards.splice(dragIndex, 1)
        newSoundboards.splice(hoverIndex, 0, reorderedItem)
        return newSoundboards
      })
      hasOrderChangedRef.current = true
    },
    [],
  )

  const handleOrderUpdate = React.useCallback(async () => {
    if (!soundboards) return

    const order = soundboards.map((sb) => sb.uuid)
    try {
      await updateSoundboardOrder(order)
      dispatch(addSnackbar({ text: "Soundboard order updated successfully." }))
      hasOrderChangedRef.current = false
    } catch (error) {
      console.error("Error updating soundboard order:", error)
      dispatch(addSnackbar({ text: "Failed to update soundboard order." }))
    }
  }, [soundboards, dispatch])

  React.useEffect(() => {
    if (hasOrderChangedRef.current) {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current)
      }
      updateTimeoutRef.current = setTimeout(() => {
        handleOrderUpdate()
      }, 500)
    }

    return () => {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current)
      }
    }
  }, [soundboards, handleOrderUpdate])

  return (
    <>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>
      )}

      <NewSoundboardModal
        open={newSoundboardModalShown}
        handleClose={() => setNewSoundboardModalShown(false)}
        onCreate={() => {
          setNewSoundboardModalShown(false)
          handleSearchSoundboards()
        }}
      />

      {soundboardToEdit && (
        <EditSoundboardModal
          open={editSoundboardModalShown}
          soundboard={soundboardToEdit}
          handleClose={() => {
            setEditSoundboardModalShown(false)
            setSoundboardToEdit(null)
          }}
          onUpdate={() => {
            setEditSoundboardModalShown(false)
            setSoundboardToEdit(null)
            handleSearchSoundboards()
          }}
        />
      )}
      <DndProvider backend={HTML5Backend}>
        <HeadingContainer sx={{ mb: 4 }}>
          <Typography component="h1" variant="h6" color="textPrimary" noWrap>
            Soundboards
          </Typography>
        </HeadingContainer>

        {soundboardsLoading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}

        {!soundboardsLoading && (
          <Container maxWidth="xl">
            <Grid container spacing={2}>
              {soundboards &&
                soundboards.map((soundboard, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={soundboard.uuid}>
                    <DraggableSoundboardCard
                      frontendWebSoundboard={soundboard}
                      index={index}
                      moveSoundboard={moveSoundboard}
                      onSetSoundboardToEdit={() => {
                        setSoundboardToEdit(soundboard)
                        setEditSoundboardModalShown(true)
                      }}
                      onOpenSoundboard={() => {
                        navigate(`/app/account/soundboards/${soundboard.uuid}`)
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </Container>
        )}
      </DndProvider>
    </>
  )
}
