import * as React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { useNavigate } from "react-router"

import BugReportIcon from "@mui/icons-material/BugReport"
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange"
import SchoolIcon from "@mui/icons-material/School"
import WarningIcon from "@mui/icons-material/Warning"
import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"

import EmailIcon from "@mui/icons-material/Email"
import Alert from "@mui/material/Alert"
import Avatar from "@mui/material/Avatar"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import FullPageContentContainer from "../../components/FullPageContentContainer"
import window from "../../lib/window"
import { useAppSelector } from "../../redux"
import ContactForm from "./components/ContactForm"

export const ContactText = () => (
  <>
    <Box sx={{ mb: 2 }}>
      User feedback is what makes this product great, so please reach out! You
      can generally expect to hear back within a few days.
    </Box>

    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} flexGrow={1}>
        <Alert
          severity="info"
          icon={<CurrencyExchangeIcon />}
          style={{ height: "100%" }}
        >
          <b>Change of Mind</b>
          <br />
          We have a generous refund policy - reach out here if you don't end up
          using your purchase. For MacOS and iOS users, you can do this at{" "}
          <Link href="https://reportaproblem.apple.com">
            reportaproblem.apple.com
          </Link>
          .
        </Alert>
      </Grid>
      <Grid item xs={12} sm={6} flexGrow={1}>
        <Alert
          severity="success"
          icon={<SchoolIcon />}
          style={{ height: "100%" }}
        >
          <b>Enterprise & Educational Discounts</b>
          <br />
          Let us know how many accounts your organization will need and we'll be
          more than happy to arrange a tailored discount.
        </Alert>
      </Grid>
      <Grid item xs={12} sm={6} flexGrow={1}>
        <Alert
          severity="warning"
          icon={<WarningIcon />}
          style={{ height: "100%" }}
        >
          <b>Check Your Junk</b>
          <br />
          Our reply may end up in your junk mailbox, so please check there if
          you don't hear back after a few days.
        </Alert>
      </Grid>
      <Grid item xs={12} sm={6} flexGrow={1}>
        <Alert
          severity="error"
          icon={<BugReportIcon />}
          style={{ height: "100%" }}
        >
          <b>Features & Bugs</b>
          <br />
          If you're sending a bug report or a feature request, please try to
          provide as much information as possible. This should include your
          device model, operating system version, and app version.
        </Alert>
      </Grid>
    </Grid>
  </>
)

export default function Contact() {
  const [errorMessage, setErrorMessage] = React.useState("")

  const navigate = useNavigate()
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  return (
    <PageContainer
      darkModeEnabled={darkModeEnabled}
      sx={{ minHeight: "100vh" }}
    >
      <AppBar title="Podcast Soundboard" />

      <Toolbar />

      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>
      )}

      <FullPageContentContainer maxWidth="md">
        <Paper
          elevation={4}
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <EmailIcon />
          </Avatar>

          <Typography
            component="h1"
            variant="h5"
            color="text.primary"
            sx={{ mb: 2 }}
          >
            Contact
          </Typography>

          <ContactText />

          <GoogleReCaptchaProvider reCaptchaKey={window.RECAPTCHA_SITE_KEY}>
            <ContactForm
              setErrorMessage={setErrorMessage}
              onSend={() => navigate("/app")}
            />
          </GoogleReCaptchaProvider>
        </Paper>
      </FullPageContentContainer>

      <Footer />
    </PageContainer>
  )
}
