import Helmet from "react-helmet"
import ReactMarkdown from "react-markdown"

import PageContainer from "@podcastsoundboard/ps-lib/components/PageContainer"

import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

import DeviceChips from "@podcastsoundboard/ps-lib/components/DeviceChips/DeviceChips"
import { useNavigate } from "react-router"
import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import { useAppSelector } from "../../redux"
import HomeContent from "../Home/components/HomeContent"
import mobileChangelogMarkdown from "./mobileChangelogMarkdown"

export default function WindowsChangelog() {
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const navigate = useNavigate()

  return (
    <PageContainer darkModeEnabled={darkModeEnabled}>
      <Helmet titleTemplate="%s">
        <title>Podcast Soundboard for Mobile Changelog</title>
        <meta
          name="description"
          content="Podcast Soundboard is the best soundboard application, available on all platforms. Featuring many customization and control options and a money-back guarantee."
        />
      </Helmet>

      <Box>
        <AppBar
          showLogo
          invert
          title="Podcast Soundboard"
          typographyColorOverride="white"
        />
        <Box sx={{ pb: 10 }}>
          <Toolbar />

          <Container maxWidth="lg" sx={{ py: 4 }}>
            <DeviceChips
              handleOpenUrl={(url) => window.open(url, "_blank")}
              navigate={navigate}
            />
            <Paper elevation={4} sx={{ py: 2, px: 4, mt: 4 }}>
              <Typography
                variant="h2"
                style={{ color: "white" }}
                sx={{ mb: 2 }}
              >
                Mobile Changelog (Android & iOS)
              </Typography>

              <ReactMarkdown>{mobileChangelogMarkdown}</ReactMarkdown>
            </Paper>
          </Container>
        </Box>

        <HomeContent />
        <Divider />
        <Footer />
      </Box>
    </PageContainer>
  )
}
