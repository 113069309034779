import * as React from "react"

import fetchMe from "@podcastsoundboard/ps-lib/api/users/fetchMe"
import User from "@podcastsoundboard/ps-lib/types/User"

import { useNavigate } from "react-router-dom"
import LoadingButton from "@mui/lab/LoadingButton"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Grid from "@mui/material/Grid"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Paper from "@mui/material/Paper"
import Select from "@mui/material/Select"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import resendConfirmationEmail from "../../../../api/users/resendConfirmationEmail"
import updateUser from "../../../../api/users/update"
import HeadingContainer from "../../../../components/HeadingContainer"
import { reasonsForSignup } from "../../../../constants"
import { useAppDispatch, useAppSelector } from "../../../../redux"
import { setCurrentUserProfile } from "../../../../redux/currentUserProfile"
import { addSnackbar } from "../../../../redux/snackbars"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material"
import deleteUser from "../../../../api/users/delete"

export default function Profile() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)

  const userUuid = currentUserProfile?.uuid

  const [userLoading, setUserLoading] = React.useState(true)
  const [user, setUser] = React.useState<User | null>(null)
  const [errorMessage, setErrorMessage] = React.useState("")

  const [lastname, setLastname] = React.useState("")
  const [firstname, setFirstname] = React.useState("")
  const [whoAmI, setWhoAmI] = React.useState("")
  const [tellUsAboutYourself, setTellUsAboutYourself] = React.useState("")
  const [numberOneFeatureRequest, setNumberOneFeatureRequest] =
    React.useState("")
  const [isDirty, setDirty] = React.useState(false)
  const [isSaving, setSaving] = React.useState(false)

  const [sendingConfirmationEmail, setSendingConfirmationEmail] =
    React.useState(false)

  const [areYouSureDeleteOpen, setAreYouSureDeleteOpen] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)

  const handleSendConfirmationEmail = () => {
    setSendingConfirmationEmail(true)
    resendConfirmationEmail()
      .then(() => {
        setSendingConfirmationEmail(false)
        dispatch(
          addSnackbar({
            text: "Confirmation email sent.",
          }),
        )
      })
      .catch((err) => {
        console.error(err)
        setSendingConfirmationEmail(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message,
        )
      })
  }

  const handleReceiveUserProfile = (user: User) => {
    setUser(user)
    setFirstname(user.firstname || "")
    setLastname(user.lastname || "")
    setWhoAmI(user.whoAmI || "")
    setTellUsAboutYourself(user.tellUsAboutYourself || "")
    setNumberOneFeatureRequest(user.numberOneFeatureRequest || "")
  }

  React.useEffect(() => {
    if (!currentUserProfile) return
    fetchMe()
      .then((user) => {
        setUserLoading(false)
        handleReceiveUserProfile(user)
      })
      .catch((err) => {
        console.error(err)
        setUserLoading(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message,
        )
      })
  }, [currentUserProfile])

  const handleSave = () => {
    setSaving(true)
    if (!currentUserProfile) return
    updateUser(currentUserProfile.uuid, {
      firstname,
      lastname,
      whoAmI,
      tellUsAboutYourself,
      numberOneFeatureRequest,
    })
      .then((user) => {
        handleReceiveUserProfile(user)
        setSaving(false)
        dispatch(addSnackbar({ text: "Profile updated." }))
        dispatch(setCurrentUserProfile(user))
      })
      .catch((err) => {
        console.error(err)
        setSaving(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message,
        )
      })
  }

  const handleDeleteUser = React.useCallback(() => {
    setIsDeleting(true)

    if (!userUuid) return
    return deleteUser(userUuid)
      .then(async () => {
        dispatch(setCurrentUserProfile(null))
        navigate("/app")
        dispatch(addSnackbar({ text: "Account deleted." }))
      })
      .catch((err) => {
        setIsDeleting(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message ||
            "Failed to delete user.",
        )
      })
  }, [dispatch, navigate, userUuid])

  return (
    <>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>
      )}

      <Dialog
        open={areYouSureDeleteOpen}
        onClose={() => setAreYouSureDeleteOpen(false)}
        aria-labelledby="are-you-sure-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="are-you-sure-dialog-title">
          Delete your account?
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1">This action cannot be undone.</Typography>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            onClick={() => handleDeleteUser()}
            loading={isDeleting}
            color="warning"
            variant="contained"
          >
            Delete
          </LoadingButton>
          <LoadingButton onClick={() => setAreYouSureDeleteOpen(false)}>
            Cancel
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <HeadingContainer>
        <Typography component="h1" variant="h6" color="textPrimary" noWrap>
          Profile
        </Typography>
      </HeadingContainer>

      {userLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", pt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {!userLoading && (
        <Container maxWidth="sm">
          <Paper sx={{ p: 2, mt: 4, pt: 3 }} elevation={4}>
            <FormGroup>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{ mb: 2 }}
                  >
                    Consider letting us know a bit about your needs in the form
                    below. This information will be used to focus development on
                    the most requested features.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={currentUserProfile?.email || ""}
                    size="small"
                    id="email"
                    label={`Email`}
                    name="email"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    InputLabelProps={{
                      shrink: Boolean(numberOneFeatureRequest),
                    }}
                    value={numberOneFeatureRequest || ""}
                    size="small"
                    id="numberOneFeatureRequest"
                    label={`What's one feature you would like to see?`}
                    name="numberOneFeatureRequest"
                    onChange={(e) => {
                      setDirty(true)
                      setNumberOneFeatureRequest(e.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel size="small" id="whoAmI">
                      I am a...
                    </InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      labelId="whoAmI"
                      label="I am a..."
                      value={whoAmI}
                      onChange={(e) => {
                        setDirty(true)
                        setWhoAmI(e.target.value)
                      }}
                    >
                      {reasonsForSignup.map((reason) => (
                        <MenuItem value={reason} key={reason}>
                          <Typography variant="body1">{reason}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    size="small"
                    InputLabelProps={{ shrink: Boolean(tellUsAboutYourself) }}
                    value={tellUsAboutYourself || ""}
                    id="tellUsAboutYourself"
                    label="How are you going to use Podcast Soundboard?"
                    name="tellUsAboutYourself"
                    onChange={(e) => {
                      setDirty(true)
                      setTellUsAboutYourself(e.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: Boolean(firstname) }}
                    id="firstName"
                    value={firstname || ""}
                    label="First Name"
                    onChange={(e) => {
                      {
                        setDirty(true)
                      }
                      setFirstname(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    size="small"
                    id="lastName"
                    InputLabelProps={{ shrink: Boolean(lastname) }}
                    value={lastname || ""}
                    label="Last Name"
                    name="lastName"
                    onChange={(e) => {
                      setDirty(true)
                      setLastname(e.target.value)
                    }}
                    autoComplete="family-name"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          checked={
                            (currentUserProfile &&
                              currentUserProfile.emailConfirmed) ||
                            undefined
                          }
                        />
                      }
                      label="Email confirmed?"
                    />
                  </FormGroup>

                  {(!currentUserProfile ||
                    !currentUserProfile.emailConfirmed) && (
                    <LoadingButton
                      onClick={handleSendConfirmationEmail}
                      loading={sendingConfirmationEmail}
                      variant="contained"
                    >
                      Resend confirmation email
                    </LoadingButton>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <LoadingButton
                    fullWidth
                    onClick={() => setAreYouSureDeleteOpen(true)}
                    loading={isDeleting}
                    variant="contained"
                    color="warning"
                  >
                    Delete Account
                  </LoadingButton>
                </Grid>

                <Grid item xs={12}>
                  <LoadingButton
                    fullWidth
                    onClick={handleSave}
                    disabled={!isDirty || !user}
                    loading={isSaving || userLoading}
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </FormGroup>
          </Paper>
        </Container>
      )}
    </>
  )
}
