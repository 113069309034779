import React from 'react'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import LoopIcon from '@mui/icons-material/Loop'
import { Box, Button } from '@mui/material'
import tinycolor from 'tinycolor2'

const SMALL_ICON_BUTTON_SIZE = 24

const PadOptionsIndicator: React.FC<{
  alwaysPlayFromStart: boolean
  loop: boolean
  multishot: boolean
  autoFadeInDuration: boolean
  fadeOutDuration: boolean
  color: string
}> = ({
  alwaysPlayFromStart,
  loop,
  multishot,
  autoFadeInDuration,
  fadeOutDuration,
  color,
}) => {
  const render = Boolean(
    alwaysPlayFromStart ||
      loop ||
      multishot ||
      fadeOutDuration ||
      autoFadeInDuration,
  )

  if (!render) return

  const textColor = tinycolor
    .mostReadable(color, ['#333', '#fff'])
    .toHexString()

  return (
    <Box
      style={{
        cursor: 'pointer',
        border: `1px solid ${color}`,
        borderRadius: '0.2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: color,
      }}
      sx={{ px: 0.5 }}
    >
      <Stack direction="row" flexWrap="nowrap">
        {alwaysPlayFromStart && (
          <Tooltip
            title="Play from start on trigger"
            placement="top"
            sx={{ mr: 0.5, color: 'white' }}
          >
            <ArrowBackIcon
              sx={{ mt: 0.5, color: 'text.primary' }}
              style={{
                color: textColor,
                height: SMALL_ICON_BUTTON_SIZE - 3,
                width: SMALL_ICON_BUTTON_SIZE - 3,
              }}
            />
          </Tooltip>
        )}

        {loop && (
          <Tooltip
            title="Loop enabled"
            sx={{ mr: 0.5, color: 'white' }}
            placement="top"
          >
            <LoopIcon
              sx={{ mt: 0.5, color: 'text.primary' }}
              style={{
                color: textColor,
                height: SMALL_ICON_BUTTON_SIZE - 3,
                width: SMALL_ICON_BUTTON_SIZE - 3,
              }}
            />
          </Tooltip>
        )}

        {multishot && (
          <Tooltip
            title="Multi-fire enabled"
            sx={{ mr: 0.5, color: 'white' }}
            placement="top"
          >
            <ContentCopyIcon
              sx={{ mt: 0.5, color: 'text.primary' }}
              style={{
                color: textColor,
                height: SMALL_ICON_BUTTON_SIZE - 5,
                width: SMALL_ICON_BUTTON_SIZE - 5,
              }}
            />
          </Tooltip>
        )}

        {Boolean(autoFadeInDuration) && (
          <Tooltip
            title={`Auto fade-in enabled (${autoFadeInDuration}s)`}
            sx={{ mr: 0.5, color: 'white' }}
            placement="top"
          >
            <KeyboardDoubleArrowUpIcon
              sx={{ mt: 0.5, color: 'text.primary' }}
              style={{
                color: textColor,
                height: SMALL_ICON_BUTTON_SIZE - 5,
                width: SMALL_ICON_BUTTON_SIZE - 5,
              }}
            />
          </Tooltip>
        )}

        {Boolean(fadeOutDuration) && (
          <Tooltip
            title={`Auto fade-out enabled (${fadeOutDuration}s)`}
            sx={{ mr: 0.5, color: 'white' }}
            placement="top"
          >
            <KeyboardDoubleArrowDownIcon
              sx={{ mt: 0.5, color: 'text.primary' }}
              style={{
                color: textColor,
                height: SMALL_ICON_BUTTON_SIZE - 5,
                width: SMALL_ICON_BUTTON_SIZE - 5,
              }}
            />
          </Tooltip>
        )}
      </Stack>
    </Box>
  )
}

export default PadOptionsIndicator
