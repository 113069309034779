import * as React from "react"
import { useNavigate } from "react-router"
import { Link as RouterLink } from "react-router-dom"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import GraphicEqIcon from "@mui/icons-material/GraphicEq"
import LowPriorityIcon from "@mui/icons-material/LowPriority"
import MusicVideoIcon from "@mui/icons-material/MusicVideo"
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk"
import SupportAgentIcon from "@mui/icons-material/SupportAgent"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"

import Copyright from "../../../../components/Copyright"
import HeadingContainer from "../../../../components/HeadingContainer"

interface AccordionBlockProps {
  panelName: string
  title: string
  expanded: string
  handleExpanded: (panel: string) => void
  children: React.ReactNode
  icon?: React.ReactNode
}

const AccordionBlock: React.FC<AccordionBlockProps> = ({
  panelName,
  title,
  expanded,
  handleExpanded,
  children,
  icon,
}) => (
  <Accordion
    expanded={expanded === panelName}
    elevation={4}
    onChange={() => handleExpanded(panelName)}
  >
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Box display="flex" alignItems="center">
        {icon}
        <Typography variant="h6" sx={{ ml: icon ? 2 : 0 }}>
          {title}
        </Typography>
      </Box>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
)

export default function Help() {
  const [expanded, setExpanded] = React.useState<string>("intro")
  const navigate = useNavigate()

  const handleExpanded = (panel: string) => {
    setExpanded(expanded !== panel ? panel : "")
  }

  return (
    <Box>
      <HeadingContainer>
        <Typography component="h1" variant="h6" color="textPrimary" noWrap>
          Help
        </Typography>
      </HeadingContainer>
      <Divider />
      <Container maxWidth="md" sx={{ mb: 2, pt: 2 }}>
        <AccordionBlock
          panelName="intro"
          title="Welcome"
          expanded={expanded}
          handleExpanded={handleExpanded}
        >
          <Typography variant="body1" sx={{ mb: 2 }}>
            Thanks for signing up to Podcast Soundboard! Get started by heading
            to the&nbsp;
            <Link
              component={RouterLink}
              to="/app/account/soundboards/new"
              underline="hover"
            >
              Soundboard page
            </Link>
            &nbsp;where you can create your first soundboard.
            <br />
            <br />
            Hit "Add Sound" to upload a file or import sounds from the Voicy or
            Freesound galleries.
          </Typography>
        </AccordionBlock>
        <AccordionBlock
          panelName="soundboards"
          title="Boards"
          icon={<MusicVideoIcon sx={{ mr: 2, color: "text.secondary" }} />}
          expanded={expanded}
          handleExpanded={handleExpanded}
        >
          <Typography variant="body1" component="div" sx={{ mb: 2 }}>
            Soundboards are created on the{" "}
            <Link
              href="/app/account/soundboards/new"
              onClick={(e) => {
                e.preventDefault()
                navigate("/app/account/soundboards/new")
              }}
              underline="hover"
            >
              Soundboard
            </Link>{" "}
            page and can be configured or deleted from the{" "}
            <Link
              href="/app/account/management"
              onClick={(e) => {
                e.preventDefault()
                navigate("/app/account/management")
              }}
              underline="hover"
            >
              Boards
            </Link>{" "}
            page. Any soundboards you create will be automatically saved to the
            cloud for use on other devices.
          </Typography>
        </AccordionBlock>

        <AccordionBlock
          panelName="audio"
          title="Files"
          icon={<GraphicEqIcon sx={{ mr: 2, color: "text.secondary" }} />}
          expanded={expanded}
          handleExpanded={handleExpanded}
        >
          <Typography variant="body1" component="div" sx={{ mb: 2 }}>
            When you upload a sound file to Podcast Soundboard, it's stored in
            the cloud and can be reused. Use the{" "}
            <Link
              href="/app/account/sounds"
              onClick={(e) => {
                e.preventDefault()
                navigate("/app/account/sounds")
              }}
              underline="hover"
            >
              Files
            </Link>{" "}
            page to rename and delete sound files.
          </Typography>
        </AccordionBlock>

        <AccordionBlock
          panelName="reordering"
          title="Re-ordering sounds"
          expanded={expanded}
          icon={<LowPriorityIcon sx={{ mr: 2, color: "text.secondary" }} />}
          handleExpanded={handleExpanded}
        >
          <Typography variant="body1" component="div" sx={{ mb: 2 }}>
            You can drag and drop sounds to rearrange them. The dragged sound
            will be inserted at the new position.
          </Typography>
        </AccordionBlock>

        <AccordionBlock
          panelName="mobile"
          icon={<PhoneInTalkIcon sx={{ mr: 2, color: "text.secondary" }} />}
          title="Unmute your mobile device"
          expanded={expanded}
          handleExpanded={handleExpanded}
        >
          <Typography variant="body1" component="div" sx={{ mb: 2 }}>
            If you're accessing Podcast Soundboard for web via a mobile device,
            check to ensure it isn't muted. For example, iPhone can't be on
            silent mode. On iPad, disable silent mode from control center.
          </Typography>
        </AccordionBlock>

        <AccordionBlock
          panelName="safari"
          title="Safari limitations"
          icon={<WarningAmberIcon sx={{ mr: 2, color: "text.secondary" }} />}
          expanded={expanded}
          handleExpanded={handleExpanded}
        >
          <Typography variant="body1" component="div" sx={{ mb: 2 }}>
            MIDI functionality is unavailable on Safari for both MacOS and iOS.
            This is a limitation of the browser.
          </Typography>
          <Typography variant="body1" component="div" sx={{ mb: 2 }}>
            Some versions of Safari 15 / MacOS Catalina have a bug that prevents
            certain audio files (including MP3's) from being loaded.
          </Typography>
          <Typography variant="body1" component="div" sx={{ mb: 2 }}>
            Mobile devices may have issues loading many large files at once.
          </Typography>
        </AccordionBlock>

        <AccordionBlock
          panelName="support"
          title="Support"
          icon={<SupportAgentIcon sx={{ mr: 2, color: "text.secondary" }} />}
          expanded={expanded}
          handleExpanded={handleExpanded}
        >
          <Typography variant="body1" component="div" sx={{ mb: 2 }}>
            If you encounter any bugs or have any queries, please reach out via
            the{" "}
            <Link
              href="/app/contact"
              onClick={(e) => {
                e.preventDefault()
                navigate("/app/contact")
              }}
              underline="hover"
            >
              contact form
            </Link>
            .
          </Typography>
        </AccordionBlock>
      </Container>
      <Copyright />
    </Box>
  )
}
