// import Fallback from '../../Fallback'
import Typography from "@mui/material/Typography"

import Chip from "@mui/material/Chip"
import { useNavigate } from "react-router"
import WindowsVersionsList from "../WindowsVersionsList"

export default function WindowsVersionsContent() {
  const navigate = useNavigate()
  return (
    <>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Downloads
      </Typography>
      <Typography variant="body1" style={{ fontSize: "1.5rem" }} sx={{ mb: 2 }}>
        A perpetual license key or active cloud subscription is required to use
        the windows app.
      </Typography>

      <Chip
        size="medium"
        clickable
        variant="outlined"
        onClick={() => navigate("/app/windows/changelog")}
        label="Changelog"
      />

      <WindowsVersionsList />
    </>
  )
}
