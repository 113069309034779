import EmailIcon from "@mui/icons-material/Email"
import WarningIcon from "@mui/icons-material/Warning"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import IconAndText from "../../../components/IconAndText"
import SoundboardPreview from "./SoundboardPreview"
import HomeCard from "./VoicyIntegration/HomeCard"

import Chip from "@mui/material/Chip"
import {
  AndroidChip,
  IOSChip,
  MacOSChip,
  WebChip,
  WindowsChip,
} from "@podcastsoundboard/ps-lib/components/DeviceChips/DeviceChips"
import { useNavigate } from "react-router"

export default function TitleCards({ hideWeb }: { hideWeb?: boolean }) {
  const navigate = useNavigate()
  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12} sm={7}>
        <HomeCard>
          <Stack
            height="100%"
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
            spacing={2}
            sx={{ pt: 2 }}
          >
            <SoundboardPreview />
            <Box sx={{ pl: 1 }}>
              <IconAndText
                icon={<WarningIcon sx={{ color: "text.secondary" }} />}
                text="If sounds don't play, take device off silent mode"
              />
            </Box>
          </Stack>
        </HomeCard>
      </Grid>
      <Grid item xs={12} sm={5} flexGrow="1">
        <Stack
          direction="column"
          spacing={2}
          flexGrow={1}
          alignItems="stretch"
          justifyContent="stretch"
        >
          <HomeCard>
            <Stack
              height="100%"
              direction="column"
              alignItems="stretch"
              justifyContent="center"
              spacing={1}
              sx={{ px: 2 }}
            >
              <Typography variant="h6">The ultimate soundboard</Typography>

              <Typography variant="body2" color="text.secondary">
                Create, control, and customize sounds to elevate every
                performance. Try it for free on web, iOS, and Android.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We're always open to feedback and collaboration.{" "}
                <b>Reach out!</b>
              </Typography>

              <Box sx={{ pt: 1 }}>
                <Chip
                  icon={<EmailIcon />}
                  clickable
                  onClick={() => navigate("/app/contact")}
                  label="Contact"
                />
              </Box>
            </Stack>
          </HomeCard>
          <HomeCard>
            <Stack
              height="100%"
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Typography variant="h6" textAlign="center">
                Available on all devices
              </Typography>

              <Stack
                direction="row"
                gap="0.5rem"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
                width="100%"
                sx={{ px: 0 }}
              >
                {!hideWeb && (
                  <WebChip
                    handleOpenUrl={(url) => window.open(url, "_blank")}
                    width="auto"
                    size="large"
                    navigate={navigate}
                    useRelativeUrl="/app/account/soundboards/new"
                  />
                )}
                <WindowsChip
                  handleOpenUrl={(url) => window.open(url, "_blank")}
                  width="auto"
                  size="large"
                  navigate={navigate}
                  useRelativeUrl="/app/windows"
                />
                <MacOSChip
                  handleOpenUrl={(url) => window.open(url, "_blank")}
                  border="1px solid black"
                  backgroundColor="white"
                  overrideColor="black"
                  width="auto"
                  size="large"
                />
                <IOSChip
                  handleOpenUrl={(url) => window.open(url, "_blank")}
                  border="1px solid black"
                  backgroundColor="white"
                  overrideColor="black"
                  width="auto"
                  size="large"
                />
                <AndroidChip
                  handleOpenUrl={(url) => window.open(url, "_blank")}
                  width="auto"
                  size="large"
                />
              </Stack>
            </Stack>
          </HomeCard>
        </Stack>
      </Grid>
    </Grid>
  )
}
