import React, { createContext, useContext, useState, ReactNode } from 'react'
import KeyboardAltIcon from '@mui/icons-material/KeyboardAlt'
import SoundboardTopBarButton from '../SoundboardTopBarButton'

// Define the context shape
interface KeyboardContextType {
  keyboardDisabled: boolean
  toggleKeyboardDisabled: () => void
}

// Create the context
const KeyboardContext = createContext<KeyboardContextType | undefined>(
  undefined,
)

// Custom hook to use the KeyboardContext
export const useKeyboard = (): KeyboardContextType => {
  const context = useContext(KeyboardContext)
  if (!context) {
    throw new Error('useKeyboard must be used within a KeyboardProvider')
  }
  return context
}

// Provider component
interface KeyboardProviderProps {
  children: ReactNode
  keyboardDisabled: boolean
  toggleKeyboardDisabled: () => void
}

export const KeyboardProvider: React.FC<KeyboardProviderProps> = ({
  children,
  keyboardDisabled,
  toggleKeyboardDisabled,
}) => {
  return (
    <KeyboardContext.Provider
      value={{ keyboardDisabled, toggleKeyboardDisabled }}
    >
      {children}
    </KeyboardContext.Provider>
  )
}

const KeyboardToggle = () => {
  const { keyboardDisabled, toggleKeyboardDisabled } = useKeyboard()

  return (
    <SoundboardTopBarButton
      tooltipTitle={
        keyboardDisabled
          ? 'Keyboard shortcuts disabled'
          : 'Keyboard shortcuts enabled'
      }
      label={keyboardDisabled ? 'Keys disabled' : 'Keys listening'}
      onClick={toggleKeyboardDisabled}
      color={keyboardDisabled ? undefined : 'primary'}
      icon={<KeyboardAltIcon />}
    />
  )
}

export default KeyboardToggle
