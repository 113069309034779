import * as React from "react"
import { useNavigate } from "react-router-dom"

import logout from "@podcastsoundboard/ps-lib/api/auth/logout"

// import PersonIcon from '@mui/icons-material/Person'
import MenuIcon from "@mui/icons-material/Menu"
import { Stack, Typography } from "@mui/material"
import Badge from "@mui/material/Badge"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { ThemeProvider } from "@mui/material/styles"

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark"
import EmailIcon from "@mui/icons-material/Email"
import HomeIcon from "@mui/icons-material/Home"
import LogoutIcon from "@mui/icons-material/Logout"
import MovieFilterIcon from "@mui/icons-material/MovieFilter"
import PersonIcon from "@mui/icons-material/Person"

import { darkTheme, lightTheme } from "../../lib/muiTheme"
import { useAppDispatch, useAppSelector } from "../../redux"
import { setCurrentUserProfile } from "../../redux/currentUserProfile"
import { addSnackbar } from "../../redux/snackbars"

export default function AppBarItems(): JSX.Element {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogoutClick = async () => {
    try {
      await logout()
      handleClose()
      dispatch(setCurrentUserProfile(null))
      dispatch(
        addSnackbar({ id: "logoutsuccess", text: "Log out successful." }),
      )
      navigate("/app")
    } catch (err) {
      console.error(err)
      handleClose()
      dispatch(setCurrentUserProfile(null))
      navigate("/app")
    }
  }

  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const darkModeEnabled = useAppSelector((state) => state.darkModeEnabled)
  const theme = darkModeEnabled ? darkTheme : lightTheme

  return (
    <ThemeProvider theme={theme}>
      {!currentUserProfile && (
        <>
          <Button color="inherit" onClick={() => navigate("/app/signin")}>
            Sign In
          </Button>
          <Button color="inherit" onClick={() => navigate("/app/signup")}>
            Sign Up
          </Button>
        </>
      )}
      <IconButton onClick={(e) => handleClick(e)} color="inherit">
        <Badge color="secondary">
          <MenuIcon color="inherit" />
        </Badge>
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => navigate("/app")}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <HomeIcon />
            <Typography>Home</Typography>
          </Stack>
        </MenuItem>
        {currentUserProfile && (
          <MenuItem onClick={() => navigate("/app/account/soundboards/new")}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <MovieFilterIcon />
              <Typography>Soundboards</Typography>
            </Stack>
          </MenuItem>
        )}
        {currentUserProfile && (
          <MenuItem onClick={() => navigate("/app/account/subscription")}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <PersonIcon />
              <Typography>Account</Typography>
            </Stack>
          </MenuItem>
        )}
        <MenuItem onClick={() => navigate("/app/blog")}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <CollectionsBookmarkIcon />
            <Typography>Guides</Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={() => navigate("/app/contact")}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <EmailIcon />
            <Typography>Contact</Typography>
          </Stack>
        </MenuItem>
        {currentUserProfile && (
          <MenuItem onClick={handleLogoutClick}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <LogoutIcon />
              <Typography>Logout</Typography>
            </Stack>
          </MenuItem>
        )}
        {currentUserProfile && currentUserProfile.isAdmin && (
          <MenuItem onClick={() => navigate("/app/admin/users")}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <AdminPanelSettingsIcon
                style={{ color: theme.palette.info.main }}
              />
              <Typography color={theme.palette.info.main}>Admin</Typography>
            </Stack>
          </MenuItem>
        )}
      </Menu>
    </ThemeProvider>
  )
}
