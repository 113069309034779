import { useCallback, useState } from 'react'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import DataObjectIcon from '@mui/icons-material/DataObject'
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum'
import PublicIcon from '@mui/icons-material/Public'
import SettingsIcon from '@mui/icons-material/Settings'
import WavingHandIcon from '@mui/icons-material/WavingHand'
import FolderZipIcon from '@mui/icons-material/FolderZip'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { DesktopSoundboard } from '../../types/DesktopSoundboard'
import FrontendWebSoundboard from '../../types/FrontendWebSoundboard'
import MobileSoundboard from '../../types/MobileSoundboard'
import AccessibleChip from '../AccessibleChip'
import AccessibleDialog from '../AccessibleDialog'
import CreatedAtChip from '../CreatedAtChip'

export interface SoundboardCardProps {
  mobileSoundboard?: MobileSoundboard
  frontendWebSoundboard?: FrontendWebSoundboard
  desktopSoundboard?: DesktopSoundboard
  onSetSoundboardToEdit: () => void
  onDuplicate?: (soundboardUuid: string) => void
  onExportSoundboardZip?: () => void
  onExportSoundboardJson?: () => void
  duplicating?: boolean
  onOpenSoundboard: () => void
  asAdmin?: boolean
}

export default function SoundboardCard({
  mobileSoundboard,
  frontendWebSoundboard,
  desktopSoundboard,
  onSetSoundboardToEdit,
  onDuplicate,
  onExportSoundboardZip,
  onExportSoundboardJson,
  duplicating,
  onOpenSoundboard,
  asAdmin,
}: SoundboardCardProps) {
  const mergedSoundboard = {
    ...frontendWebSoundboard,
    ...mobileSoundboard,
    ...desktopSoundboard,
  }

  const userEmail = mergedSoundboard.user?.email
  const {
    uuid: soundboardUuid,
    createdAt,
    name,
    public: isPublic,
    requestFeatured,
    featured,
    description,
  } = mergedSoundboard

  const allPads = [
    ...(frontendWebSoundboard?.pads || []),
    ...(mobileSoundboard?.pads || []),
    ...(desktopSoundboard?.freeformKeys || []),
    ...Object.values(desktopSoundboard?.keys || {}).filter((k) =>
      Boolean(k.trackname),
    ),
  ]
  const numberOfPads = allPads.length

  const [open, setOpen] = useState(false)

  if (!soundboardUuid) return null

  return (
    <>
      <AccessibleDialog
        open={open}
        onClose={() => !duplicating && setOpen(false)}
        fullWidth
        maxWidth="sm"
        aria-label={'Duplicate this soundboard?'}
      >
        <DialogTitle>Duplicate this soundboard?</DialogTitle>

        <DialogContent>
          <Typography variant="body1">
            A copy of this soundboard will be made with the same name and same
            settings.
          </Typography>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            loading={duplicating}
            onClick={() => {
              onDuplicate && onDuplicate(soundboardUuid)
              setOpen(false)
            }}
            // onClick={() => handleWebDuplicate()}
            variant="contained"
          >
            Duplicate
          </LoadingButton>
          <LoadingButton disabled={duplicating} onClick={() => setOpen(false)}>
            Cancel
          </LoadingButton>
        </DialogActions>
      </AccessibleDialog>

      <Paper sx={{ p: 2 }} style={{ height: '100%' }} elevation={4}>
        <Stack
          direction="column"
          alignItems={'stretch'}
          spacing={1}
          style={{ height: '100%' }}
        >
          <Box>
            <Typography
              variant="body1"
              style={{ fontSize: '1.2rem' }}
              data-soundboard-uuid={soundboardUuid}
            >
              {name || <i>None</i>}
            </Typography>

            {asAdmin && userEmail && (
              <Typography variant="body2" color="text.secondary">
                {userEmail}
              </Typography>
            )}
          </Box>

          <Stack direction="row" gap="0.5rem" flexWrap="wrap">
            {createdAt && <CreatedAtChip createdAt={createdAt} />}

            {numberOfPads > 0 && (
              <Chip
                size="small"
                variant="outlined"
                // color="info"
                label={`${numberOfPads} Sounds`}
              />
            )}

            {numberOfPads === 0 && (
              <Chip
                size="small"
                variant="outlined"
                color="warning"
                label={'No Sounds'}
              />
            )}

            {isPublic && (
              <Chip
                size="small"
                color="success"
                label="Public"
                icon={<PublicIcon />}
              />
            )}

            {requestFeatured && (
              <Chip
                size="small"
                label="Publish request"
                color="warning"
                icon={<WavingHandIcon />}
              />
            )}

            {featured && (
              <Chip
                size="small"
                label="Published"
                color="success"
                icon={<PhotoAlbumIcon />}
              />
            )}
          </Stack>

          {description && (
            <Box style={{ maxHeight: '70px', overflowY: 'scroll' }}>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ whiteSpace: 'pre' }}
              >
                {description}
              </Typography>
            </Box>
          )}

          <Stack
            direction="row"
            width="100%"
            alignItems="stretch"
            justifyContent="flex-start"
            gap="0.5rem"
            flexWrap="wrap"
          >
            <AccessibleChip
              onClick={() => onOpenSoundboard()}
              size="small"
              label="Open"
              color="info"
              icon={<OpenInNewIcon />}
            />

            {onDuplicate && (
              <AccessibleChip
                onClick={() => setOpen(true)}
                size="small"
                label="Clone"
                icon={<ContentCopyIcon />}
                color="info"
              />
            )}

            <AccessibleChip
              onClick={() => soundboardUuid && onSetSoundboardToEdit()}
              size="small"
              icon={<SettingsIcon />}
              label="Settings"
            />

            {onExportSoundboardZip && (
              <Tooltip title="Export as ZIP (sounds and config)">
                <AccessibleChip
                  size="small"
                  clickable
                  onClick={() =>
                    onExportSoundboardZip && onExportSoundboardZip()
                  }
                  label="Export ZIP"
                  color="primary"
                  icon={<FolderZipIcon />}
                />
              </Tooltip>
            )}

            {onExportSoundboardJson && (
              <Tooltip title="Export as JSON (no sounds)">
                <AccessibleChip
                  size="small"
                  clickable
                  onClick={() =>
                    onExportSoundboardJson && onExportSoundboardJson()
                  }
                  label="Export JSON"
                  color="primary"
                  icon={<DataObjectIcon />}
                />
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </Paper>
    </>
  )
}
