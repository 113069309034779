import { Chip, ChipProps } from '@mui/material'
import { Ref, forwardRef } from 'react'
import AccessibleComponent from '../AccessibleComponent'

const AccessibleChip = forwardRef((props: ChipProps, ref: Ref<unknown>) => {
  return (
    <AccessibleComponent component={Chip} componentProps={props} ref={ref} />
  )
})

export default AccessibleChip
