import { CSSProperties, ReactNode } from 'react'

import Paper from '@mui/material/Paper'
import { SxProps } from '@mui/material/styles'

const HeadingContainer = ({
  children,
  minHeight,
  style,
  sx,
}: {
  minHeight?: string
  children?: ReactNode
  style?: CSSProperties
  sx?: SxProps
}) => {
  return (
    <>
      <Paper
        style={{
          display: 'flex',
          minHeight: minHeight || '64px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '0.5rem',
          flexWrap: 'wrap',
          paddingLeft: '24px',
          paddingRight: '24px',
          borderRadius: 0,
          flexShrink: 0,
          ...(style || {}),
        }}
        sx={sx}
      >
        {children}
      </Paper>
    </>
  )
}

export default HeadingContainer
