import * as React from "react"

import debounce from "lodash.debounce"

import passwordStrength from "@podcastsoundboard/ps-lib/api/auth/passwordStrength"

import LoadingButton from "@mui/lab/LoadingButton"
import { Paper } from "@mui/material"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import FormGroup from "@mui/material/FormGroup"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import updateUser from "../../../../api/users/update"
import HeadingContainer from "../../../../components/HeadingContainer"
import { useAppDispatch, useAppSelector } from "../../../../redux"
import { addSnackbar } from "../../../../redux/snackbars"

export default function Profile() {
  const dispatch = useAppDispatch()
  const currentUserProfile = useAppSelector((state) => state.currentUserProfile)
  const [passwordValidationErrorMessage, setPasswordValidationErrorMessage] =
    React.useState("")

  const [errorMessage, setErrorMessage] = React.useState("")

  const [oldPassword, setOldPassword] = React.useState("")
  const [newPassword, setNewPassword] = React.useState("")
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("")

  const [isSaving, setSaving] = React.useState(false)

  const handleSave = () => {
    setSaving(true)
    if (!currentUserProfile) return
    updateUser(currentUserProfile.uuid, {
      oldPassword,
      newPassword,
    })
      .then(() => {
        setOldPassword("")
        setNewPassword("")
        setConfirmNewPassword("")

        setErrorMessage("")

        setSaving(false)
        dispatch(
          addSnackbar({
            id: "passwordUpdated",
            text: "Successfully changed password.",
          }),
        )
      })
      .catch((err) => {
        console.error(err)
        setSaving(false)
        setErrorMessage(
          (err.response && err.response.data && err.response.data.error) ||
            err.message,
        )
      })
  }

  React.useEffect(() => {
    const debounced = debounce(() => {
      if (!newPassword) {
        setPasswordValidationErrorMessage("")
        return
      }
      if (
        newPassword &&
        confirmNewPassword &&
        newPassword !== confirmNewPassword
      ) {
        setPasswordValidationErrorMessage("Passwords do not match.")
        return
      }

      passwordStrength(newPassword)
        .then(({ errorMessage }) => {
          setPasswordValidationErrorMessage(errorMessage)
        })
        .catch((err) => {
          console.error("Error checking password strength", err)
          dispatch(
            addSnackbar({
              text: "Error checking password strength.",
            }),
          )
        })
    }, 200)

    debounced()

    return debounced.cancel
  }, [
    dispatch,
    newPassword,
    confirmNewPassword,
    setPasswordValidationErrorMessage,
  ])

  return (
    <>
      {errorMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{errorMessage}</Alert>
        </Stack>
      )}

      <HeadingContainer sx={{ mb: 4 }}>
        <Typography component="h1" variant="h6" color="textPrimary" noWrap>
          Change Password
        </Typography>
      </HeadingContainer>

      <Container maxWidth="sm">
        <Paper sx={{ p: 2, mt: 2 }} elevation={4}>
          <FormGroup>
            <Box component="form" noValidate>
              <Grid sx={{ mt: 0 }}>
                <TextField
                  autoComplete="password"
                  name="oldPassword"
                  required
                  type="password"
                  fullWidth
                  id="oldPassword"
                  value={oldPassword || ""}
                  label="Existing Password"
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </Grid>

              <Grid item sx={{ mt: 2 }} xs={12}>
                <TextField
                  autoComplete="password"
                  name="newPassword"
                  required
                  type="password"
                  fullWidth
                  id="newPassword"
                  value={newPassword || ""}
                  label="New Password"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Grid>

              <Grid item sx={{ mt: 2 }} xs={12}>
                <TextField
                  autoComplete="password"
                  name="confirmNewPassword"
                  required
                  type="password"
                  fullWidth
                  id="confirmNewPassword"
                  value={confirmNewPassword || ""}
                  label="Confirm New Password"
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
              </Grid>
            </Box>

            <Grid>
              {passwordValidationErrorMessage && (
                <Typography sx={{ mt: 2 }} color="error">
                  {passwordValidationErrorMessage}
                </Typography>
              )}

              <LoadingButton
                fullWidth
                onClick={handleSave}
                disabled={
                  !oldPassword ||
                  !newPassword ||
                  newPassword !== confirmNewPassword
                }
                loading={isSaving}
                sx={{ mt: 2 }}
                variant="contained"
              >
                Save
              </LoadingButton>
            </Grid>
          </FormGroup>
        </Paper>
      </Container>
    </>
  )
}
